import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const OtpVerification = ({ onOtpVerification }) => {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const email = localStorage.getItem("email");

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleVerifyOtp = async () => {
    const otpdata = {
      email: email,
      step: "second",
      otp: otp,
    };
    try {
      const signupUser = await axios.post(
        "https://apis.shareslate.fun/api/users/signup",
        otpdata
      );

      console.log(
        "🚀 ~ handleSubmit ~ signupUser:",
        signupUser.data.data.token
      );

      if (signupUser.data.data.token) {
        navigate("/inviteusers");
      }
      // navigate("/otp");
      // console.log("Sign Up:", formData);
    } catch (err) {
      console.log("Error", err.response.data.message);

      alert(err.response.data.message);
      // console.log("🚀 ~ handleSubmit ~ err:", err.response);
      // setTimeout(() => {
      //   setuserExist(err.response.data.message);
      //   console.log(userExist);
      //   console.log("Hello World from Codecademy");
      // }, 2000);
    }
  };

  return (
    <div className="w-[55%] mx-auto border px-5 py-10 rounded-lg my-5">
      <h2 className="text-2xl text-center uppercase font-bold text-[#5E72E4]">
        OTP Verification
      </h2>
      <form className="flex flex-col gap-8 mt-7">
        <input
          className="px-3 py-4 outline-none rounded-full border "
          type="text"
          name="otp"
          placeholder="Enter OTP..."
          value={otp}
          onChange={handleOtpChange}
          required
        />

        <button
          className="py-4 text-white border bg-[#5E72E4] rounded-full "
          type="button"
          onClick={handleVerifyOtp}
        >
          Verify OTP
        </button>
      </form>
    </div>
  );
};

export default OtpVerification;
