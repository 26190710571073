import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import avatarImg from '../assets/images/avatarImg.jpg';

const MiniHeader = () => {
  const token = localStorage.getItem('token');
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      console.log('offset Value', offset);
      if (offset > 100) {
        // You can adjust this offset value
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const logOutUser = () => {
    localStorage.removeItem('token');
  };

  return (
    <div
      className={` bg-[#000] py-2 w-[100%] flex gap-5 text-white justify-center items-center ${
        isSticky ? 'sticky' : ''
      }`}
    >
      <Link to={token ? '/inviteusers' : 'signin'}>
        <div className="border-[#5E72E4] md:tracking-wider border-[2px] px-3 py-1 rounded-full uppercase text-sm md:text-md bg-[#5E72E4] hover:bg-[transparent] ">
          FunParty
        </div>
      </Link>
      <div className="flex gap-5">
        {token ? (
          <>
            <Link>
              <div className="  px-3 py-1 uppercase ">
                <img
                  className="w-[30px] h-[30px] rounded-full "
                  src={avatarImg}
                  alt="avtartImg"
                />
              </div>
            </Link>
            <Link to="/" onClick={logOutUser}>
              <div className="mr-10 border-[#5E72E4] tracking-wider border-[2px] px-3 py-1 rounded-full uppercase hover:bg-[#5E72E4] ">
                Log out
              </div>
            </Link>
          </>
        ) : (
          <>
            <Link to="/signin">
              <div className="border-[#5E72E4] md:tracking-wider border-[2px] px-1 md:px-3 py-1 rounded-full uppercase text-sm md:text-md hover:bg-[#5E72E4] ">
                Sign In
              </div>
            </Link>
            <Link to="/signup">
              <div className="mr-10 border-[#5E72E4] md:tracking-wider border-[2px] px-1  md:px-3 py-1 rounded-full uppercase text-sm md:text-md hover:bg-[#5E72E4] ">
                Sign Up
              </div>
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default MiniHeader;
