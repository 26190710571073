import React from "react";
import { Link } from "react-router-dom";
// import funlandinglogo from "../assets/images/funlandinglogo.png";
const CommonHeader = ({ funlandinglogo, pageName }) => {
  return (
    <header className="w-[100%] gradientHeader">
      <div className="flex gap-5 items-center py-2 px-8">
        <div className="w-[150px]">
          <Link to="/">
            <img width="100%" src={funlandinglogo} alt="funlandingpagelog" />
          </Link>
        </div>
        <div className="text-white w-[100%] text-sm md:text-lg font-semibold">
          {pageName}
        </div>
      </div>
    </header>
  );
};

export default CommonHeader;
