import React from "react";
import googlePlay from "../assets/images/googleplay.png";
import appstorePlay from "../assets/images/appstoreplay.png";
import { Link } from "react-router-dom";

// social media Images
// import youtu from "../assets/images/youtu.png";
// import face from "../assets/images/face.png";
// import insta from "../assets/images/insta.png";
// import lindin from "../assets/images/linkdin.png";

const Downloadappsection = () => {
  return (
    <section className="w-[100%] py-12 ">
      <div className="w-[80%] mx-auto  container">
        <div className=" w-[70%] DownloadappsectionGradient py-10  px-5 mx-auto rounded-3xl">
          <div className="flex flex-col justify-center items-center">
            <h1 className="text-2xl sm:text-3xl font-bold text-white text-center">
              Create. Post. Have Fun.
            </h1>
            <h6 className="font-bold text-white my-5">Dowload Now</h6>
            <div className="flex gap-5">
              <Link
                to="https://play.google.com/store/apps/details?id=com.shareslatefun&pcampaignid=web_share"
                target="_blank"
              >
                <img
                  className="sm:h-[150px] sm:w-[140px] w-[80px]"
                  src={googlePlay}
                  alt="googleplaystoeImg"
                />
              </Link>
              <Link
                to="https://apps.apple.com/us/app/share-slate-fun/id1670628391"
                target="_blank"
              >
                <img
                  src={appstorePlay}
                  className="sm:h-[150px] sm:w-[140px] w-[80px]"
                  alt="googleplaystoeImg"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Downloadappsection;
