import React from 'react';
import youtu from '../assets/images/youtu.png';
import face from '../assets/images/face.png';
import insta from '../assets/images/insta.png';
import lindin from '../assets/images/linkdin.png';
import { Link } from 'react-router-dom';

const SocialMediaSection = () => {
  return (
    <div>
      <div className="w-[55%] mx-auto">
        <p className="mt-[37px] text-[13px] text-justify">
          Meet Share Slate Fun by Share Slate Inc.! It's a fun social network
          made to bring people together through shared videos. Whether you make
          videos, love watching them, or just want to connect with similar
          folks, Share Slate Fun has lively communities for everyone. Join watch
          parties, create, and share your videos, and enjoy awesome,
          high-quality content.{' '}
        </p>

        <div className="flex justify-center items-center mt-10 gap-4 pb-7">
          <Link to="https://www.youtube.com/@Shareslate" target="_blank">
            <img src={youtu} alt="youte img" />
          </Link>
          <Link to="https://www.facebook.com/shareslateinc/" target="_blank">
            <img src={face} alt="youte img" />
          </Link>
          <Link to="https://instagram.com/shareslateinc/" target="_blank">
            <img src={insta} alt="youte img" />
          </Link>
          <Link
            to="https://www.linkedin.com/company/share-slate-inc/"
            target="_blank"
          >
            <img src={lindin} alt="youte img" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaSection;
