import React from 'react';
import { useNavigate } from 'react-router-dom';

export const categoryFaqsData = [
  {
    id: 1,
    text: '1. How do I create an account?',
    img: 'https://new.axilthemes.com/demo/react/blogar/images/posts/post-tect-05.webp?imwidth=640',
    faqdetail: {
      para: 'To create an account, download the app from your device’s app store (App Store or Google Play) open it, and select the “Sign Up” option. Follow the prompts to enter your username, email, create a password, and fill out your profile details. Make sure the email address you have used is active. Users will not be allowed to change the email address after an account has been created. If you are having any issues creating the account, please open a case by following',
      link: 'support@shareslate.fun',
    },

    // faqdetail:
    //   'To create an account, download the app from your device’s app store (App Store or Google Play) open it, and select the “Sign Up” option. Follow the prompts to enter your username, email, create a password, and fill out your profile details.',
  },

  {
    id: 2,
    // type: 'category1',

    // heading: 'faq',
    text: ' 2. What should I do if I forget my password?',
    img: 'https://new.axilthemes.com/demo/react/blogar/images/posts/post-tect-05.webp?imwidth=640',
    faqdetail: {
      para: 'If you forget your password, click on the “Forgot password?” button. You can go to “Forgot password?”, put your email and a secure verification code will be sent to your registered email address. After code verification you can change your password. Please make sure to check your spam folder if you do not see the secure code in your mail inbox. Sign in with your new password and move forward. If you are having any issues resetting the password, please open a case by going to',
      link: 'support@shareslate.fun',
    },
    // faqdetail:
    //   'If you forget your password, click on the need help in signing in? You can go to forgot password. Write your email and send OTP, get the OTP verification done and now you can change your password. Sign in with new password.',
  },

  {
    id: 3,
    text: ' 3. How can I complete my profile?',
    img: 'https://new.axilthemes.com/demo/react/blogar/images/posts/post-tect-05.webp?imwidth=640',
    faqdetail: {
      para: 'To complete your profile, go to your profile, click on the “Edit Profile” section in the app. Add a profile picture, enter your date of birth, gender, location, about me, phone number, username, and your first and last name. Make sure that date of birth, location (country and state) are accurate along with your name.',
      link: '',
    },
  },

  {
    id: 4,
    text: ' 4. How do I earn coins on Share Slate Fun?',
    img: 'https://new.axilthemes.com/demo/react/blogar/images/posts/post-tect-05.webp?imwidth=640',
    faqdetail: {
      para: 'You can earn coins by scrolling & creating content and completing various in-app activities. Make sure your profile is complete to maximize your earning potential. Make sure that date of birth, location (country and state) are accurate along with your name in the profile. We need this information to provide you with the tax forms. If the information is not accurate, your withdrawal will be delayed or put on hold. In certain extreme cases the amount earned may be forfeited.',
      link: '',
    },
  },

  {
    id: 5,
    text: ' 5. What are the benefits of having a complete profile?',
    img: 'https://new.axilthemes.com/demo/react/blogar/images/posts/post-tect-05.webp?imwidth=640',
    faqdetail: {
      para: 'A complete profile makes you eligible for the earning opportunity, increases your visibility and credibility on the platform, enhances engagement with your content, and unlocks additional earning opportunities and features within the app.',
      link: '',
    },
  },

  {
    id: 6,

    text: ' 6. How can I withdraw my earnings?',
    img: 'https://new.axilthemes.com/demo/react/blogar/images/posts/post-tect-05.webp?imwidth=640',
    faqdetail: {
      para: 'Once you have earned $50, you can withdraw it by going to the wallet and click on the withdrawal enter your bank details and submit the request. The amount may be subject to change from time to time. Share Slate bears no responsibility for deposits made to incorrect bank accounts due to inaccurate bank details provided by the user. Also Share Slate do not bear any responsibility for the delays in the withdrawal, due to the delays from third party payment integrators and providers.',
      link: '',
    },
  },

  {
    id: 7,
    text: ' 7. How do I report inappropriate content or users?',
    img: 'https://new.axilthemes.com/demo/react/blogar/images/posts/post-tect-05.webp?imwidth=640',
    faqdetail: {
      para: 'If you encounter inappropriate content or users, click on the share button then click on report content. Choose the reason for reporting and submit it for review. Our team will investigate and take appropriate action. If required. We will contact you for more information. For more details, please read Share Slate',
      link: '',
      termslink: 'Terms & Conditions',
    },
  },

  {
    id: 8,

    text: ' 8. Can I customize my privacy settings?',
    img: 'https://new.axilthemes.com/demo/react/blogar/images/posts/post-tect-05.webp?imwidth=640',
    faqdetail: {
      para: 'You can customize your privacy settings by going to “Settings and Privacy” in your profile through the options menu. Here you can control who can see your posts, comment on your post, send you messages, and follow you. Adjust the settings to suit your preferences.',
      link: '',
    },
  },

  {
    id: 9,
    text: ' 9. How do I contact customer support?',
    img: 'https://new.axilthemes.com/demo/react/blogar/images/posts/post-tect-05.webp?imwidth=640',
    faqdetail: {
      para: 'If you need assistance, you can contact our customer support team by emailing us at support@shareslate.fun. We aim to respond to all inquiries within 24 hours.',
      link: 'support@shareslate.fun',
    },
  },

  {
    id: 10,
    text: ' 10. What should I do if I encounter technical issues with the app?',
    img: 'https://new.axilthemes.com/demo/react/blogar/images/posts/post-tect-05.webp?imwidth=640',
    faqdetail: {
      para: 'If you are experiencing any technical issues, try restarting the app or your device after clearing cache. If the problem persists, check for app updates in your device’s app store (App store for apple and Play store for android). You can also go to your profile click Options menu and click on Support & Help. You can report a case with Issue type, attachment, and brief description of the issue you are facing. You can also provide us with access to your account for a limited time to help troubleshoot the issue, if required. For further assistance, contact customer support at support@shareslate.com with a detailed description of the issue.',
      link: '',
    },
  },

  {
    id: 11,
    text: ' 11. Delete and re-register using the same email?',
    img: 'https://new.axilthemes.com/demo/react/blogar/images/posts/post-tect-05.webp?imwidth=640',
    faqdetail: {
      para: 'If you choose to delete your account on Share Slate, all your accounts within the Share Slate ecosystem, including Share Slate Fun and Share Slate Fun Party, will be deleted. Your account will enter a "soft delete" state, allowing you to recover it within 14 days by simply logging in. If you do not recover your account within this period, it will be permanently deleted. After the 14-day window, you can use your old email address to create a new account and rejoin the Share Slate network.',
      link: '',
    },
  },

  {
    id: 12,
    text: ' 12. What type of videos can I upload in Minis and Fun Video portal?',
    img: 'https://new.axilthemes.com/demo/react/blogar/images/posts/post-tect-05.webp?imwidth=640',
    faqdetail: {
      para: 'You can capture and upload your Minis (short videos). Fun videos are accepted exclusively through uploads.',
      link: '',
    },
  },

  {
    id: 13,
    text: ' 13. Is there a limit to the length of videos I can upload?',
    img: 'https://new.axilthemes.com/demo/react/blogar/images/posts/post-tect-05.webp?imwidth=640',
    faqdetail: {
      para: 'Minis must be at least 15 seconds long, while Fun Videos have no length restrictions.',
      link: '',
    },
  },

  {
    id: 14,
    text: '14. How can I make my videos private or restrict who can see them?',
    img: 'https://new.axilthemes.com/demo/react/blogar/images/posts/post-tect-05.webp?imwidth=640',
    faqdetail: {
      para: 'You can make your video private or restricted from the options in the videos.',
      link: '',
    },
  },

  {
    id: 15,
    text: '15. Are there any guidelines or rules I need to follow when creating content?',
    img: 'https://new.axilthemes.com/demo/react/blogar/images/posts/post-tect-05.webp?imwidth=640',
    faqdetail: {
      para: 'Although Share Slate Fun is an 18+ platform, we encourage users to create content that aligns with our Terms & Conditions. Pornographic material, abusive, and misleading content are strictly prohibited. For more details, please refer to the Share Slate',
      link: '',
      termslink: 'Terms & Conditions',
    },
  },

  {
    id: 16,
    text: '16. Can I download videos from Share Slate Fun to my device?',
    img: 'https://new.axilthemes.com/demo/react/blogar/images/posts/post-tect-05.webp?imwidth=640',
    faqdetail: {
      para: 'Share Slate Fun does not currently allow users to download content to their devices. This feature may be considered for future versions.',
      link: '',
    },
  },
];

const CategoryFaqs = () => {
  const navigate = useNavigate();

  const handleFaqClick = (id) => {
    navigate(`/faqs/${id}`);
    window.scrollTo(0, 0);
  };

  return (
    <div className="w-[90%] md:w-[60%] mx-auto py-20">
      <h2 className="font-bold text-[36px] text-center"> FAQ's</h2>

      <div className="flex flex-col gap-10 my-8">
        {categoryFaqsData.map((item) => {
          return (
            <div
              key={item.id}
              className=" bg-[#F0F2F5] border-l-[5px] border-l-[#f47681] hover:border-l-[#e45662] hover:shadow-lg group flex items-center justify-between rounded-2xl px-10 py-5 cursor-pointer"
              onClick={() => handleFaqClick(item.id)}
            >
              {/* <h5 className="text-red-600  ">{item?.heading}</h5> */}
              <p className="text-[18px] md:text-[20px] font-semibold group-hover:text-[#F84959] ">
                {item?.text}
              </p>
              <div className="text-[#F84959] font-bold">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  class="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
                  />
                </svg>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CategoryFaqs;
