import React from "react";
import ImgDiv from "../commoncomponents/ImgDiv";
import ContentDiv from "../commoncomponents/ContentDiv";
import earnImg from "../assets/images/earncoinImg.png";
import arrowImg from "../assets/images/arrowimg.png";

const FeaturesCreators = () => {
  return (
    <section className="w-[100%] py-10 ">
      <div className="w-[80%] mx-auto  container">
        <h2 className="text-center font-bold text-[1.7rem] mb-20 sm:text-[2.5rem] text-shadow-x0-y4-blur4-spread0">
          Features built for Creators
        </h2>
        <div className="flex flex-col sm:flex sm:flex-row-reverse mt-8">
          <ImgDiv Img={earnImg} />
          <ContentDiv
            h2="Make sustainable income with 
            Share Slate."
            list1="Earn doing what you love."
            list2="The more your followers watch your content the more you 
            earn on Share Slate Fun."
            arrowImg={arrowImg}
          />
        </div>
      </div>
    </section>
  );
};

export default FeaturesCreators;
