import React from "react";
import ImgDiv from "../commoncomponents/ImgDiv";
import ContentDiv from "../commoncomponents/ContentDiv";

import minisImg from "../assets/images/minisImg.png";
import arrowImg from "../assets/images/arrowimg.png";

const Minis = () => {
  return (
    <section className="w-[100%] py-10 ">
      <div className="w-[80%] mx-auto  container">
        <div className="flex flex-col sm:flex sm:flex-row">
          <ImgDiv Img={minisImg} />
          <ContentDiv
            h2="Explore the fun through Share Slate Videos and Minis."
            list1="Share Slate fun lets you connect with other creators."
            list2="Find and connect with people you want and have Fun with our cool features."
            arrowImg={arrowImg}
          />
        </div>
      </div>
    </section>
  );
};

export default Minis;
