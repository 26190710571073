import React from 'react';

// import images
import funlandinglogo from '../assets/images/funlandinglogo.png';
import shareslatecomlog from '../assets/images/shareslatecomlog.png';
// import funLogo from '../assets/images/logofun.svg';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <>
      <header className="w-[100%] gradientHeader">
        <div className="lg:w-[75%] md:flex md:items-center md:justify-between py-2 px-8 headerResponsive">
          <div className=" w-[200px]">
            <Link to="/">
              <img
                width="100%"
                src={shareslatecomlog}
                alt="funlandingpagelog"
              />
            </Link>
          </div>
          <div className="text-white">
            <h2 className="textRight font-semibold md:text-xl font-inter text-white lg:relative lg:right-[7rem] tracking-wider md:tracking-[.4em] mt-3 md:font-light">
              SHARE SLATE FUN PRESENTS
            </h2>
            <h1 className="tracking-wide lg:left-72 lg:relative md:text-3xl font-bold italic text-shadow-x4-y4-blur4-spread0">
              FUNPARTY
            </h1>
            <h2 className="textRight font-semibold lg:text-end md:mt-2 md:tracking-widest md:text-xl lg:relative lg:left-[18rem]">
              Watch VIDEOS With Friends
            </h2>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
