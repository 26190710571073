import React from "react";
import ImgDiv from "../commoncomponents/ImgDiv";
import ContentDiv from "../commoncomponents/ContentDiv";

// import images
import entertainmentImg from "../assets/images/entertainmentImg.png";
import arrowImg from "../assets/images/arrowimg.png";

const Entertainment = () => {
  return (
    <section className="w-[100%] py-10 ">
      <div className="w-[80%] mx-auto container">
        <div className="flex flex-col sm:flex sm:flex-row-reverse">
          <ImgDiv Img={entertainmentImg} />
          <ContentDiv
            h2="Discover a new world of entertainment."
            list1="Watch and enjoy curated content just for you."
            list2="Lead the trends with your own hashtags."
            arrowImg={arrowImg}
          />
        </div>
      </div>
    </section>
  );
};

export default Entertainment;
