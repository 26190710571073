import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import axios from "axios";
import Header from '../sections/Header';
import Footer from '../sections/Footer';
import Loader from '../commoncomponents/Loader';
import { API } from '../api';
// import jwt_decode from "jwt-decode";

const LoginPage = () => {
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  const errorRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    try {
      const username = usernameRef.current.value;
      const password = passwordRef.current.value;

      const body = {
        email: username,
        password: password,
      };

      const response = await API.v1.Auth.login(body);

      // Simulate API call to authenticate user (Replace with your actual API endpoint)
      // const response = await axios.post(
      //   'https://apis.shareslate.fun/api/users/login',
      //   {
      //     email: username,
      //     password: password,
      //   }
      // );
      // console.log("apiResponse", response);

      // Extract token from API response
      const authToken = response.data.data.token;
      // console.log(authToken);
      // Store token in localStorage
      localStorage.setItem('token', authToken);
      if (authToken) {
        // console.log("auth is set to the local storage");
        navigate('/inviteusers');
      }
    } catch (err) {
      // Handle authentication error
      errorRef.current.textContent =
        'Failed to sign in. Please check your credentials.';
    }
    // setIsLoading(false);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  return (
    <div className="h-screen flex flex-col justify-between ">
      <Header />
      <div className="md:w-[35%] w-[95%] mx-auto border px-5 py-10 rounded-lg my-5">
        <h2 className="text-2xl text-center uppercase font-bold text-[#5E72E4]">
          Sign In
        </h2>
        <form className="flex flex-col gap-8 mt-7" onSubmit={handleSubmit}>
          <input
            ref={usernameRef}
            className="px-3 py-4 outline-none rounded-full border"
            type="email"
            name="username"
            placeholder="Email"
            required
          />
          <input
            ref={passwordRef}
            className="px-3 py-4 outline-none rounded-full border"
            type="password"
            name="password"
            placeholder="Password"
            required
          />
          <button
            className="py-4 text-white border bg-[#5E72E4] rounded-full"
            type="submit"
          >
            {isLoading ? <Loader /> : 'Sign In'}
          </button>
          <p ref={errorRef} className="text-red-500"></p>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default LoginPage;

// import React, { useState } from "react";
// import Header from "../sections/Header";
// import Footer from "../sections/Footer";

// const LoginPage = () => {
//   const [formData, setFormData] = useState({
//     username: "",
//     password: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     console.log(name, value);
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));

//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Implement backend logic for sign-in here
//     console.log("Sign In:", formData);
//     // setFormData("");
//   };

//   return (
//     <>
//       <Header />
//       <div className="w-[35%] mx-auto border px-5 py-10 rounded-lg my-5">
//         <h2 className="text-2xl text-center uppercase font-bold text-[#5E72E4]">
//           Sign In
//         </h2>
//         <form className="flex flex-col gap-8 mt-7" onSubmit={handleSubmit}>
//           <input
//             className="px-3 py-4 outline-none rounded-full border "
//             type="email"
//             name="username"
//             placeholder="Enter your Email..."
//             value={formData.username}
//             onChange={handleChange}
//             required
//           />
//           <input
//             className="px-3 py-4 outline-none rounded-full border "
//             type="password"
//             name="password"
//             placeholder="Password"
//             value={formData.password}
//             onChange={handleChange}
//             required
//           />
//           <button
//             className="py-4 text-white border bg-[#5E72E4] rounded-full "
//             type="submit"
//           >
//             Sign In
//           </button>
//         </form>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default LoginPage;
