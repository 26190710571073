import React from "react";
import CommonHeader from "../commoncomponents/CommonHeader";
import Footer from "../sections/Footer";
import { Link } from "react-router-dom";
import funlandinglogo from "../assets/images/funlandinglogo.png";
import pdfFile from "../assets/pdfFile.pdf";

const Terms = () => {
  return (
    <>
      <CommonHeader
        funlandinglogo={funlandinglogo}
        pageName="|  TERMS & CONDITIONS"
      />

      <div className="w-[90%] ml-auto mr-auto  my-10 rounded-lg p-[2rem]">
        <div>
          <h2 className="italic text-lg font-medium  my-4">
            Last Updated, November 22, 2023
          </h2>
          <p>
            These Terms & Conditions ({" "}
            <strong className="font-bold text-lg">“Terms”</strong>) apply to
            your access to, and use of the websites, mobile applications, and
            other online products and services (collectively, the{" "}
            <strong className="font-bold text-lg"> “Services”</strong>) provided
            by Share Slate Inc. (
            <strong className="font-bold text-lg">“Share Slate”</strong> or{" "}
            <strong className="font-bold text-lg"> “we”</strong>). By
            downloading, installing, accessing, posting to, interacting with, or
            using our Services, you agree to these Terms, including the
            mandatory arbitration provision and class action waiver in Section
            16, the Privacy Policy, and the Community Guidelines. If you do not
            agree to these Terms, the Privacy Policy, or the Community
            Guidelines, do not download, install, access, post to, interact
            with, or use our Services.
          </p>

          <p>
            We may make changes to these Terms from time to time. If we make
            changes, we will provide you with notice of such changes by updating
            the date at the top of these Terms. Unless we say otherwise in our
            notice, the amended Terms will be effective immediately, and your
            continued use of our Services will confirm your acceptance of the
            changes. If you do not agree to the amended Terms, you must stop
            using our Services.
          </p>

          <p>
            If you have any questions about these Terms or our Services, please
            contact us at{" "}
            <a
              href="mailto:contact@shareslate.fun"
              className="text-blue-400 underline"
            >
              contact@shareslate.fun
            </a>
            .
          </p>

          <h3 className="text-lg  font-medium my-4">1. Privacy</h3>
          <p>
            For information about how we collect, use, share, and otherwise
            process information about you, please see our{" "}
            <Link to="/privacypolicy" className="text-blue-400 underline">
              Privacy Policy
            </Link>
            .
          </p>

          <h3 className="text-lg  font-medium my-4">2. Eligibility</h3>
          <p>
            You must be at least 18 years of age (or the age of legal majority
            where you live) to use our Services. If you are under 18 years of
            age (or the age of legal majority where you live), you may not use
            our Services. If you use our Services on behalf of another person or
            entity, (a) all references to “you” throughout these Terms will
            include that person or entity, (b) you represent that you are
            authorized to accept these Terms on that person’s or entity’s
            behalf, and (c) in the event you or that person or entity violates
            these Terms, that person or entity agrees to be responsible to us.
          </p>

          <section>
            <h3 className="text-lg  font-medium my-4">
              3. User Accounts and Account Security
            </h3>
            <p>
              You may need to register an account to access some of our
              Services. If you register an account, you must provide accurate
              account information and promptly update that information if it
              changes. You also must maintain the security of your account and
              promptly notify us if you discover or suspect that someone has
              accessed your account without your permission. As part of our
              security procedures, you must treat your account credentials as
              confidential, and you must not disclose your account credentials
              to any other person or entity. We reserve the right to reclaim
              usernames, including on behalf of businesses or individuals that
              hold legal claims, including trademark rights, in those usernames.
            </p>

            <p>
              You shall not buy, sell, or transfer any aspect of your account
              (including your username). You agree that your account is not
              transferable, by operation of law or otherwise, and that in the
              event of your death, incapacity, or unavailability, we may
              terminate any rights to your account or User Content.
            </p>

            <p>
              You can deactivate your account by notifying and asking us to take
              measures to suspend the login and use of your account. In
              connection with such a request, we may require you to provide
              valid identifying information that is consistent with the
              registered identity information associated with your account. We
              have the right to refuse your request if such information is not
              provided.
            </p>

            <p>
              We reserve the right to suspend, block, or terminate your account
              at any time, immediately and without notice, if we believe
              activities associated with your account violate these Terms, the
              Community Guidelines, or otherwise pose a danger to Share Slate,
              the Services, other users, or any third party. Further, if your
              account has not been used for more than six (6) months, we reserve
              the right to suspend, close, retrieve, or replace your account, or
              delete or de-identify all records associated with your account.
            </p>

            <p>
              If you choose to delete, deactivate your account, or your account
              is terminated by us, you will not be able to retrieve any content
              or information associated with your account. As such, we recommend
              that you separately save copies of any User Content or other
              information associated with your account that you wish to retain.
            </p>
          </section>

          <section>
            <h3 className="text-lg  font-medium my-4">4. User Content</h3>
            <p>
              Our Services may allow you and other users to create, transmit,
              post, store, and share content, including messages, text, photos,
              videos, audio (including musical works and non-musical works), and
              other materials (collectively,{" "}
              <strong className="font-bold text-lg">“User Content”).</strong>{" "}
              Except for the license you grant below, you retain all rights in
              and to your User Content, as between you and Share Slate.
            </p>

            <p>
              You grant Share Slate and its subsidiaries, affiliates, and
              service providers a perpetual, nonexclusive, royalty-free,
              worldwide, fully paid, transferable, and sublicensable license to
              use, reproduce, modify, adapt, publish, translate, create
              derivative works from, distribute, publicly perform, and display
              your User Content and any name, username, or likeness provided in
              connection with your User Content in all media formats and
              channels now known or later developed without compensation to you.
              When you post or otherwise share User Content on or through our
              Services, you understand that your User Content and any associated
              information (such as your username or profile photo) may be
              visible to others and may be subject to automated scanning to
              customize user experiences, including targeted advertising.
            </p>

            <p>
              You may not create, post, store, or share any User Content that
              violates these Terms, the Community Guidelines, or for which you
              do not have all the rights necessary to grant us the license
              described above. You represent and warrant that your User Content,
              and our use of such Content as permitted by these Terms, will not
              violate any rights of or cause injury to any person or entity.
              Although we have no obligation to screen, edit, or monitor User
              Content, we may delete or remove User Content at any time and for
              any reason with or without notice.
            </p>

            <p>
              Notwithstanding the foregoing, we cannot and do not control what
              people and others do or say, or the content they transmit or post,
              and do not undertake to review all User Content as it is
              transmitted or before it is posted on Share Slate. Moreover, we
              cannot ensure the prompt removal of objectionable material as it
              is transmitted or after it has been posted. Accordingly, we assume
              no liability for any action or inaction regarding transmissions,
              communications, or content provided by any user or third party,
              even if you access them through the Services.
            </p>
          </section>

          <section>
            <h3 className="text-lg  font-medium my-4">
              5. Prohibited Conduct and Content
            </h3>
            <p>
              You may not violate the Community Guidelines, any applicable law,
              contract, intellectual property right, or other third-party right,
              nor commit any tortious acts, and you are solely responsible for
              your conduct while using our Services. In particular, you will
              not:
            </p>
            <ul
              className="ml-5 my-2"
              style={{
                listStyle: "inside",
                listStyleType: "initial",
              }}
            >
              <li>
                Engage in any harassing, threatening, intimidating, predatory,
                or stalking conduct;
              </li>
              <li>
                Use or attempt to use another user’s account without
                authorization from that user and Share Slate;
              </li>
              <li>
                Impersonate or post on behalf of any person or entity or
                otherwise misrepresent your affiliation with a person or entity;
              </li>
              <li>Sell, resell, or commercially use our Services;</li>
              <li>
                Copy, reproduce, distribute, publicly perform, or publicly
                display all or portions of our Services, except as expressly
                permitted by us or our licensors;
              </li>
              <li>
                Modify our Services, remove any proprietary rights notices or
                markings, or otherwise make any derivative works based upon our
                Services;
              </li>
              <li>
                Use our Services other than for their intended purpose and in
                any manner that could interfere with, disrupt, negatively
                affect, or inhibit other users from fully enjoying our Services,
                or that could damage, disable, overburden, or impair the
                functioning of our Services in any manner;
              </li>
              <li>
                Reverse engineer any aspect of our Services or do anything that
                might discover source code or bypass or circumvent measures
                employed to prevent or limit access to any part of our Services;
              </li>
              <li>
                Use any data mining, robots, or similar data-gathering or
                extraction methods designed to scrape or extract data from our
                Services;
              </li>
              <li>
                Develop or use any applications that interact with our Services
                without our prior written consent;
              </li>
              <li>
                Send, distribute, or post spam, unsolicited or bulk commercial
                electronic communications, chain letters, or pyramid schemes;
              </li>
              <li>
                Bypass or ignore instructions contained in our robots.txt file;
                or
              </li>
              <li>
                Use our Services for any illegal or unauthorized purpose, or
                engage in, encourage, or promote any activity that violates
                these Terms and/or the Community Guidelines.
              </li>
            </ul>
            <p>
              You also may not post or otherwise share User Content that is
              confidential and/or that you do not have all necessary rights to
              disclose. You may not create, post, store, or share any User
              Content that:
            </p>
            <ul
              className="ml-5 my-2"
              style={{
                listStyle: "inside",
                listStyleType: "initial",
              }}
            >
              <li>
                Is unlawful, libelous, defamatory, obscene, pornographic,
                indecent, lewd, suggestive, harassing, threatening, invasive of
                privacy or publicity rights, abusive, inflammatory, or
                fraudulent, content that incites violence;
              </li>
              <li>
                Would constitute, encourage, or provide instructions for a
                criminal offense, violate the rights of any party, or otherwise
                create liability or violate any local, state, national, or
                international law;
              </li>
              <li>
                May infringe any patent, trademark, trade secret, copyright, or
                other intellectual or proprietary right of any party;
              </li>
              <li>
                Contains or depicts any statements, remarks, or claims that do
                not reflect your honest views and experiences;
              </li>
              <li>
                Impersonates or misrepresents your affiliation with any person
                or entity;
              </li>
              <li>
                Contains any unsolicited promotions, political campaigning,
                advertising, or solicitations;
              </li>
              <li>
                Contains any private or personal information of a third party
                without such third party’s consent;
              </li>
              <li>
                Contains any viruses, corrupted data, or other harmful,
                disruptive, or destructive files or content; or
              </li>
              <li>
                In our sole judgment, is objectionable, restricts, or inhibits
                any other person from using or enjoying our Services, or may
                expose Share Slate or others to any harm or liability of any
                type.
              </li>
            </ul>
          </section>

          <section>
            <h3 className="text-lg  font-medium my-4">
              6. Ownership; Limited License
            </h3>
            <p>
              The Services, including the text, graphics, images, photographs,
              videos, illustrations, software, functionality, and other content
              contained therein, are owned by Share Slate or our licensors and
              are protected under both United States and foreign laws. Except as
              explicitly stated in these Terms, all rights in and to the
              Services are reserved by us or our licensors. Subject to your
              compliance with these Terms, you are hereby granted a limited,
              nonexclusive, nontransferable, non-sublicensable, revocable
              license to access and use our Services for your own personal,
              noncommercial use. Any use of the Services other than as
              specifically authorized herein, without our prior written
              permission, is strictly prohibited, will terminate the license
              granted herein, and will violate our intellectual property rights.
            </p>
          </section>

          <section>
            <h3 className="text-lg  font-medium my-4">7. Trademarks</h3>
            <p>
              Share Slate and our logos, our product or service names, our
              slogans and the look and feel of the Services are trademarks of
              Share Slate and may not be copied, imitated, or used, in whole or
              in part, without our prior written permission. All third-party
              trademarks, registered trademarks, product names, company names,
              or logos mentioned on the Services are the property of their
              respective owners. Reference to any products, services, processes,
              or other information by trade name, trademark, manufacturer,
              supplier, or otherwise does not constitute or imply endorsement,
              sponsorship, or recommendation by us.
            </p>
          </section>

          <section>
            <h3 className="text-lg  font-medium my-4">8. Feedback</h3>
            <p>
              You may voluntarily post, submit, or otherwise communicate to us
              any questions, comments, suggestions, ideas, original or creative
              materials, or other information about Share Slate or our Services
              (collectively,{" "}
              <strong className="font-bold text-lg">“Feedback”</strong>). You
              understand that we may use such Feedback for any purpose,
              commercial or otherwise, without acknowledgment or compensation to
              you, including, without limitation, to develop, copy, publish, or
              improve the Feedback in Share Slate’s sole discretion. You
              understand that Share Slate may treat Feedback as nonconfidential.
            </p>
          </section>

          <section>
            <h3 className="text-lg  font-medium my-4">
              9. Repeat Infringer Policy; Copyright Complaints
            </h3>
            <p>
              In accordance with the Digital Millennium Copyright Act ({" "}
              <strong className="font-bold text-lg">“DMCA”</strong>) and other
              applicable laws, we have adopted a policy of terminating, in
              appropriate circumstances, the accounts of users who repeatedly
              infringe the intellectual property rights of others. If you
              believe that anything on our Services infringes any copyright that
              you own or control, you may notify Share Slate’s DMCA Notice
              Department as follows:
            </p>
            <address>
              Tracy
              <br />
              Share Slate Inc.
              <br />
              6730 Flat Iron St.,
              <br />
              Tracy, CA 95377
              <br />
              United States
              <br />
              Phone: +1 (972) 322-7812
              <br />
              Email:{" "}
              <a
                href="mailto:report@shareslate.fun"
                className="text-blue-400 underline"
              >
                report@shareslate.fun
              </a>
            </address>
            <p>
              Please see{" "}
              <a
                className="text-blue-500 underline"
                href={pdfFile}
                rel="noreferrer"
                target="_blank"
              >
                17 U.S.C. § 512(c)(3)
              </a>{" "}
              for the requirements of a proper notification. Also, please note
              that if you knowingly misrepresent that any activity or material
              on our Services is infringing, you may be liable for certain costs
              and damages.
            </p>
          </section>

          <section>
            <h3 className="text-lg  font-medium my-4">
              10. Third-Party Content
            </h3>
            <p>
              We may provide information about third-party products, services,
              activities, or events, or we may allow third parties to make their
              content and information available on or through the Services
              (collectively,{" "}
              <strong className="text-lg font-bold">
                {" "}
                “Third-Party Content”).
              </strong>{" "}
              We provide Third-Party Content as a service to those interested in
              such content. Your dealings or correspondence with third parties
              and your use of or interaction with any Third-Party Content are
              solely between you and the third party. Share Slate does not
              control or endorse, and makes no representations or warranties
              regarding, any Third-Party Content, and your access to and use of
              such Third-Party Content is at your own risk.
            </p>
          </section>

          <section>
            <h3 className="text-lg  font-medium my-4">11. Indemnification</h3>
            <p>
              To the fullest extent permitted by applicable law, you will
              indemnify, defend, and hold harmless Share Slate, our parent
              companies, subsidiaries, licensors, service providers, and our and
              their affiliates, and each of our respective officers, directors,
              agents, partners, and employees (individually and collectively,
              the{" "}
              <strong className="font-bold text-lg">
                {" "}
                “Share Slate Parties”)
              </strong>{" "}
              from and against any losses, liabilities, claims, demands,
              damages, expenses, or costs
              <strong className="font-bold text-lg">(“Claims”) </strong>arising
              out of or related to: (a) your access to or use of the Services;
              (b) your User Content or Feedback; (c) your violation of these
              Terms or the Community Guidelines; (d) your violation,
              misappropriation, or infringement of any rights of another
              (including intellectual property rights or privacy rights); or (e)
              your conduct in connection with the Services. You agree to
              promptly notify Share Slate Parties of any third-party Claims,
              cooperate with Share Slate Parties in defending such Claims, and
              pay all fees, costs, and expenses associated with defending such
              Claims (including, but not limited to, attorneys' fees). You also
              agree that the Share Slate Parties will have control of the
              defense or settlement, at Share Slate’s sole option, of any
              third-party Claims. This indemnity is in addition to, and not in
              lieu of, any other indemnities set forth in a written agreement
              between you and Share Slate or the other Share Slate Parties.
            </p>
          </section>

          <section>
            <h3 className="text-lg  font-medium my-4">12. Disclaimers</h3>
            <p>
              Your use of our Services is at your sole risk. Except as otherwise
              provided in a writing by us, our Services and any content therein
              are provided “as is” and “as available” without warranties of any
              kind, either express or implied, including, but not limited to,
              implied warranties of merchantability, fitness for a particular
              purpose, title, and non-infringement. In addition, Share Slate
              does not represent or warrant that our Services are accurate,
              complete, reliable, current, or error-free. While Share Slate
              attempts to make your use of our Services and any content therein
              safe, we cannot and do not represent or warrant that our Services
              or servers are free of viruses or other harmful components. You
              assume the entire risk as to the quality and performance of the
              Services.
            </p>
          </section>

          <section>
            <h3 className="text-lg  font-medium my-4">
              13. Limitation of Liability
            </h3>
            <p>
              To the fullest extent permitted by applicable law, Share Slate and
              the other Share Slate Parties will not be liable to you under any
              theory of liability-whether based in contract, tort, negligence,
              strict liability, warranty, or otherwise-for any indirect,
              consequential, exemplary, incidental, punitive, or special
              damages, or lost profits, even if Share Slate or the other Share
              Slate Parties have been advised of the possibility of such
              damages. Without limiting the foregoing, and to the fullest extent
              permitted by applicable law, Share Slate will not be liable for
              any loss or damage caused by a distributed denial-of-service
              attack, viruses, or other technologically harmful material that
              may infect your computer, mobile device, equipment, computer
              programs, data, or other proprietary material due to your use of
              Share Slate or the Services, or items obtained through Share Slate
              or the Services, or due to your downloading of any material posted
              on Share Slate or any website or application linked to it.
            </p>
            <p>
              The total liability of Share Slate and the other Share Slate
              Parties for any claim arising out of or relating to these Terms or
              our Services, regardless of the form of the action, is limited to
              the greater of $25.00 or the amount paid by you to use our
              Services.
            </p>
            <p>
              The limitations set forth in this Section 13 will not limit or
              exclude liability for the gross negligence, fraud, or intentional
              misconduct of Share Slate or the other Share Slate Parties, or for
              any other matters in which liability cannot be excluded or limited
              under applicable law. Additionally, some jurisdictions do not
              allow the exclusion or limitation of incidental or consequential
              damages, so the above limitations or exclusions may not apply to
              you.
            </p>
          </section>

          <section>
            <h3 className="text-lg  font-medium my-4">14. Release</h3>
            <p>
              To the fullest extent permitted by applicable law, you release
              Share Slate and the other Share Slate Parties from responsibility,
              liability, claims, demands, and/or damages (actual and
              consequential) of every kind and nature, known and unknown
              (including, but not limited to, claims of negligence), arising out
              of or related to disputes between users and the acts or omissions
              of third parties. If you are a consumer who resides in California,
              you hereby waive your rights under California Civil Code § 1542,
              which provides: “A general release does not extend to claims which
              the creditor does not know or suspect to exist in his or her favor
              at the time of executing the release, which if known by him or her
              must have materially affected his or her settlement with the
              debtor.”
            </p>
          </section>

          <section>
            <h3 className="text-lg  font-medium my-4">
              15. Transfer and Processing Data
            </h3>
            <p>
              In order for Share Slate to provide our Services, you agree that
              we may process, transfer, and store information about you in the
              United States and other countries, where you may not have the same
              rights and protections as you do under local law.
            </p>
          </section>

          <section>
            <h3 className="text-lg  font-medium my-4">
              16. Dispute Resolution; Binding Arbitration
            </h3>
            <p>
              Please read the following section carefully because it requires
              you to arbitrate certain disputes and claims with Share Slate and
              limits the manner in which you can seek relief from us, unless you
              opt out of arbitration by following the instructions set forth
              below. No class or representative actions or arbitrations are
              allowed under this arbitration agreement. In addition, arbitration
              precludes you from suing in court or having a jury trial.
            </p>

            <p>
              <strong>No Representative Actions.</strong> You and Share Slate
              agree that any dispute arising out of or related to these Terms or
              our Services is personal to you and Share Slate and that any
              dispute will be resolved solely through individual action and will
              not be brought as a class arbitration, class action, or any other
              type of representative proceeding.
            </p>

            <p>
              <strong>Arbitration of Disputes.</strong> Except for small claims
              disputes in which you or Share Slate seeks to bring an individual
              action in small claims court located in the county of your billing
              address or disputes in which you or Share Slate seeks injunctive
              or other equitable relief for the alleged unlawful use of
              intellectual property, you and{" "}
              <strong className="font-bold text-lg">
                Share Slate waive your rights to a jury trial and to have any
                dispute arising out of or related to these Terms or our Services
                resolved in court.
              </strong>{" "}
              Instead, for any dispute or claim that you have against Share
              Slate or relating in any way to the Services, you agree to first
              contact Share Slate and attempt to resolve the claim informally by
              sending a written notice of your claim ({" "}
              <strong className="font-bold text-lg">“Notice”)</strong> to Share
              Slate by email at{" "}
              <a
                href="mailto:contact@shareslate.fun"
                className="text-blue-400 underline"
              >
                contact@shareslate.fun
              </a>
              . The Notice must: (a) include your name, residence address, email
              address, and telephone number; (b) describe the nature and basis
              of the claim; and (c) set forth the specific relief sought. Our
              notice to you will be similar in form to that described above. If
              you and Share Slate cannot reach an agreement to resolve the claim
              within thirty (30) days after such Notice is received, then either
              party may submit the dispute to binding arbitration administered
              by JAMS or, under the limited circumstances set forth above, in
              court. All disputes submitted to JAMS will be resolved through
              confidential, binding arbitration before one arbitrator.
              Arbitration proceedings will be held in San Jose, California or
              may be conducted telephonically or via video conference for
              disputes alleging damages less than $5,000, unless you are a
              consumer, in which case you may elect to hold the arbitration in
              your county of residence in accordance with the JAMS Streamlined
              Arbitration Rules and Procedures (
              <strong className="font-bold text-lg">“JAMS Rules”</strong>). For
              purposes of this Section, you will be deemed a{" "}
              <strong className="font-bold text-lg">“consumer”</strong> if you
              use the Services for your personal, family, or household purposes.
              The most recent version of the JAMS Rules are available on the{" "}
              <a
                className="text-blue-400 underline"
                href="https://www.jamsadr.com/rules-streamlined-arbitration/"
              >
                JAMS website
              </a>{" "}
              and are hereby incorporated by reference. You either acknowledge
              and agree that you have read and understand the JAMS Rules or
              waive your opportunity to read the JAMS Rules and waive any claim
              that the JAMS Rules are unfair or should not apply for any reason.
            </p>

            <p>
              You and Share Slate agree that these Terms affect interstate
              commerce and that the enforceability of this Section 16 will be
              substantively and procedurally governed by the Federal Arbitration
              Act, 9 U.S.C. § 1, et seq. (the{" "}
              <strong className="font-bold text-lg"> “FAA”</strong>), to the
              maximum extent permitted by applicable law. As limited by the FAA,
              these Terms and the JAMS Rules, the arbitrator will have exclusive
              authority to make all procedural and substantive decisions
              regarding any dispute and to grant any remedy that would otherwise
              be available in court, including the power to determine the
              question of arbitrability. The arbitrator may conduct only an
              individual arbitration and may not consolidate more than one
              individual’s claims, preside over any type of class or
              representative proceeding, or preside over any proceeding
              involving more than one individual.
            </p>

            <p>
              The arbitrator, Share Slate, and you will maintain the
              confidentiality of any arbitration proceedings, judgments, and
              awards, including, but not limited to, all information gathered,
              prepared and presented for purposes of the arbitration or related
              to the dispute(s) therein. The arbitrator will have the authority
              to make appropriate rulings to safeguard confidentiality, unless
              the law provides to the contrary. The arbitration will allow for
              the discovery or exchange of non-privileged information relevant
              to the dispute. The duty of confidentiality does not apply to the
              extent that disclosure is necessary to prepare for or conduct the
              arbitration hearing on the merits, in connection with a court
              application for a preliminary remedy or in connection with a
              judicial challenge to an arbitration award or its enforcement, or
              to the extent that disclosure is otherwise required by law or
              judicial decision.
            </p>

            <p>
              You and Share Slate agree that for any arbitration you initiate,
              you will pay the filing fee (up to a maximum of $250 if you are a
              consumer), and Share Slate will pay the remaining JAMS fees and
              costs. For any arbitration initiated by Share Slate, Share Slate
              will pay all JAMS fees and costs. You and Share Slate agree that
              the state or federal courts of the State of California and the
              United States sitting in San Joaquin County, California have
              exclusive jurisdiction over any appeals and the enforcement of an
              arbitration award.
            </p>

            <p className="font-bold text-lg">
              Any claim arising out of or related to these Terms or our Services
              must be filed within one year after such claim arose; otherwise,
              the claim is permanently barred, which means that you and Share
              Slate will not have the right to assert the claim.
            </p>

            <p>
              <strong className="font-bold text-lg">
                You have the right to opt out of binding arbitration within 30
                days of the date you first accepted the terms of this Section 16
                by emailing{" "}
              </strong>{" "}
              <a
                href="mailto:contact@shareslate.fun"
                className="text-blue-400 underline"
              >
                contact@shareslate.fun
              </a>
              . In order to be effective, the opt-out notice must include your
              full name and address and clearly indicate your intent to opt out
              of binding arbitration. By opting out of binding arbitration, you
              are agreeing to resolve disputes in accordance with Section 17.
            </p>

            <p>
              If any portion of this Section 16 is found to be unenforceable or
              unlawful for any reason: (a) the unenforceable or unlawful
              provision shall be severed from these Terms; (b) severance of the
              unenforceable or unlawful provision shall have no impact
              whatsoever on the remainder of this Section 16 or the parties’
              ability to compel arbitration of any remaining claims on an
              individual basis pursuant to this Section 16; and (c) to the
              extent that any claims must therefore proceed on a class,
              collective, consolidated, or representative basis, such claims
              must be litigated in a civil court of competent jurisdiction and
              not in arbitration, and the parties agree that litigation of those
              claims shall be stayed pending the outcome of any individual
              claims in arbitration. Further, if any part of this Section 16 is
              found to prohibit an individual claim seeking public injunctive
              relief, that provision will have no effect to the extent such
              relief is allowed to be sought out of arbitration, and the
              remainder of this Section 16 will be enforceable.
            </p>
          </section>

          <section>
            <h3 className="text-lg  font-medium my-4">
              17. Governing Law and Venue
            </h3>
            <p>
              Any dispute arising from these Terms and your use of the Services
              will be governed by and construed and enforced in accordance with
              the laws of California, except to the extent preempted by U.S.
              federal law, without regard to conflict of law rules or principles
              (whether of California or any other jurisdiction) that would cause
              the application of the laws of any other jurisdiction. Any dispute
              between the parties that is not subject to arbitration or cannot
              be heard in small claims court will be resolved in the state or
              federal courts of California and the United States, respectively,
              sitting in San Joaquin County, California.
            </p>

            <h3 className="text-lg  font-medium my-4">
              18. Modifying and Terminating our Services
            </h3>
            <p>
              We reserve the right to modify our Services or to suspend or stop
              providing all or portions of our Services at any time. You also
              have the right to stop using our Services at any time. We are not
              responsible for any loss or harm related to your inability to
              access or use our Services.
            </p>

            <h3 className="text-lg  font-medium my-4">19. Severability</h3>
            <p>
              If any provision or part of a provision of these Terms is
              unlawful, void or unenforceable, that provision or part of the
              provision is deemed severable from these Terms and does not affect
              the validity and enforceability of any remaining provisions.
            </p>

            <h3 className="text-lg  font-medium my-4">20. Miscellaneous</h3>
            <p>
              The failure of Share Slate to exercise or enforce any right or
              provision of these Terms will not operate as a waiver of such
              right or provision. The section titles in these Terms are for
              convenience only and have no legal or contractual effect. Except
              as otherwise provided herein, these Terms are intended solely for
              the benefit of the parties and are not intended to confer
              third-party beneficiary rights upon any other person or entity.
              You agree that communications and transactions between us may be
              conducted electronically.
            </p>
          </section>
        </div>

        {/* <div className="accordion">
          {accordionData.map(({ title, content }) => (
            <Accordion title={title} content={content} />
          ))}
        </div> */}
      </div>

      <Footer />
    </>
  );
};

export default Terms;
