import React from "react";
import { PacmanLoader } from "react-spinners";

const Loader = () => {
  return (
    <div className="loader-container">
      <PacmanLoader color="blue" />
    </div>
  );
};

export default Loader;
