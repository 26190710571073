import React from "react";
import ImgDiv from "../commoncomponents/ImgDiv";
import ContentDiv from "../commoncomponents/ContentDiv";

import meaningfulImg from "../assets/images/meaningfulImg.png";
import arrowImg from "../assets/images/arrowimg.png";

const MeaningfulConnectionl = () => {
  return (
    <section className="w-[100%] py-10 ">
      <div className="w-[80%] mx-auto  container">
        <div className="flex flex-col sm:flex sm:flex-row-reverse">
          <ImgDiv Img={meaningfulImg} />
          <ContentDiv
            h2="Meaningful connection with 
            your followers. "
            list1="Send messages to your followers to develop a stronger 
            connection."
            list2="And have fun"
            arrowImg={arrowImg}
          />
        </div>
      </div>
    </section>
  );
};

export default MeaningfulConnectionl;
