import React from "react";
import CommonHeader from "../commoncomponents/CommonHeader";
import Footer from "../sections/Footer";

import funlandinglogo from "../assets/images/funlandinglogo.png";

const AboutPage = () => {
  return (
    <>
      <CommonHeader funlandinglogo={funlandinglogo} pageName="|  ABOUT US" />
      <div className="w-[80%] mx-auto my-24">
        <h2 className="text-2xl font-semibold ">Create. Post. Have Fun.</h2>
        <p className="mt-5">
          Meet Share Slate Fun by Share Slate Inc.! It's a fun social network
          made to bring people together through shared videos. Whether you make
          videos, love watching them, or just want to connect with similar
          folks, Share Slate Fun has lively communities for everyone. Join watch
          parties, create, and share your videos, and enjoy awesome,
          high-quality content. Come be a part of the lively tapestry of shared
          moments and connections on Share Slate Fun!
        </p>
        <p className="mt-5">
          Share Slate Fun has a 18+ audience policy. No kids, just mature adults
          interacting and living their normal lives. No filters, and no BS. Just
          real and organic entertainment.
        </p>
        <p className="mt-5">
          We want to get to know YOU. With our popular features like Minis, Fun
          Party, and Long Videos, we aim to share in the moments of your
          everyday life. Whether it's cooking with friends and family, enjoying
          videos together, expressing your fashion sense, or showcasing your
          favorite hobby, Share Slate Fun is all about you!
        </p>

        <p className="mt-5">Kindly,</p>
        <p className="mt-5">The Share Slate Team 🧡</p>
      </div>
      <Footer />
    </>
  );
};

export default AboutPage;
