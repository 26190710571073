import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { categoryFaqsData } from '../faq/CategoryFaqs';

const FaqDetail = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const id = pathname.split('/').pop();

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredFaqs, setFilteredFaqs] = useState([]);
  const [selectedFaq, setSelectedFaq] = useState(null);

  useEffect(() => {
    const faq = categoryFaqsData.find((faq) => faq.id === parseInt(id));
    setSelectedFaq(faq);
  }, [id]);

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    if (value) {
      const filtered = categoryFaqsData.filter((faq) =>
        faq.text.toLowerCase().includes(value)
      );
      setFilteredFaqs(filtered);
    } else {
      setFilteredFaqs([]);
    }
  };

  const handleItemClick = (id) => {
    setSelectedFaq(categoryFaqsData.find((faq) => faq.id === id));
    window.scrollTo(0, 0);
  };

  return (
    <div className="w-[90%] md:w-[80%] min-h-screen mx-auto py-16">
      <div className="lg:flex gap-12">
        <div className="w-[100%] lg:w-[65%]">
          <div className="flex flex-col gap-6 pb-16">
            <h2 className="basis-[30%] text-[#000000] text-[30px] md:text-[45px] font-bold md:leading-[52px]">
              {selectedFaq ? selectedFaq.text : 'FAQ not found'}
            </h2>
            {selectedFaq && (
              <ul
                className="ml-4"
                style={{
                  listStyle: 'inside',
                  listStyleType: 'initial',
                }}
              >
                <p>
                  {selectedFaq.faqdetail.para}{' '}
                  <Link to="/terms" className="text-blue-800 underline">
                    {selectedFaq.faqdetail.termslink
                      ? selectedFaq.faqdetail.termslink
                      : ''}
                  </Link>
                </p>
                <a
                  href="mailto:support@shareslate.fun"
                  className="text-blue-800 underline"
                >
                  {selectedFaq.faqdetail.link ? selectedFaq.faqdetail.link : ''}
                </a>
              </ul>
            )}
          </div>

          {/*  2nd flex for Winners to social icons and hr line. */}
          {/* <div className="flex flex-col gap-4">
            <h1 className="text-[18px] md:text-[20px] font-medium text-[#000000] -mt-10">
              Winners are recognized for outstanding app design, innovation,
              ingenuity, and technical achievement
            </h1>
            <img src={blogimg1} alt="img" className=" mt-9" />
            <h4 className="text-[12px] mt-2">
              The Apple Design Award trophy, created by the Apple Design team,
              is a symbol of achievement and excellence.
            </h4>
            <p className="text-[18px] text-[#000000] mt-5">
              Apple today named eight app and game developers receiving an Apple
              Design Award, each one selected for being thoughtful and creative.
              Apple Design Award winners bring distinctive new ideas to life and
              demonstrate deep mastery of Apple technology. The apps spring up
              from developers large and small, in every part of the world, and
              provide users with new ways of working, creating, and playing.
              <br />
              <br />
              “Every year, app and game developers demonstrate exceptional
              craftsmanship and we’re honoring the best of the best,” said Ron
              Okamoto, Apple’s vice president of Worldwide Developer Relations.
              “Receiving an Apple Design Award is a special and laudable
              accomplishment. Past honorees have made some of the most
              noteworthy apps and games of all time. Through their vision,
              determination, and exacting standards, the winning developers
              inspire not only their peers in the Apple developer community, but
              all of us at Apple, too.”
            </p>

            <h1 className="text-[36px] font-medium text-[#000000]">
              Apple Design Award Winners: Apps
            </h1>
            <p className="text-[18px] text-[#000000] mt-5">
              Apple today named eight app and game developers receiving an Apple
              Design Award, each one selected for being thoughtful and creative.
              Apple Design Award winners bring distinctive new ideas to life and
              demonstrate deep mastery of Apple technology. The apps spring up
              from developers large and small, in every part of the world, and
              provide users with new ways of working, creating, and playing.
              <br />
              <br />
              “Every year, app and game developers demonstrate exceptional
              craftsmanship and we’re honoring the best of the best,” said Ron
              Okamoto, Apple’s vice president of Worldwide Developer Relations.
              “Receiving an Apple Design Award is a special and laudable
              accomplishment. Past honorees have made some of the most
              noteworthy apps and games of all time. Through their vision,
              determination, and exacting standards, the winning developers
              inspire not only their peers in the Apple developer community, but
              all of us at Apple, too.”
            </p>

            
          </div> */}
        </div>
        <div className="w-[100%] lg:w-[35%] mt-10 md:mt-0">
          <div className="flex flex-col items-center my-7 justify-center p-10 bg-[#FBFBFD] rounded-lg">
            <p className="text-lg font-semibold">Search</p>
            <div className="h-[1px] w-full my-5 bg-[lightgray]"></div>
            <input
              type="search"
              value={searchTerm}
              onChange={handleSearch}
              placeholder="Search"
              className="w-full rounded-full outline-none border-none bg-[#F0F2F5] p-3"
            />
          </div>
          {searchTerm && (
            <div className="flex flex-col items-center">
              {filteredFaqs.map((faq) => (
                <div
                  key={faq.id}
                  onClick={() => handleItemClick(faq.id)}
                  className="w-full p-2 cursor-pointer hover:bg-gray-100"
                >
                  {faq.text}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FaqDetail;
