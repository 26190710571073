import axios from 'axios';

import Auth from './Auth';

export const PATHS = '';

export const api = axios.create({
  baseURL: 'https://apis.shareslate.fun/api',
});

api.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('token');

    if (token) {
      config.headers = { Authorization: `Bearer ${token}` };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export const API = {
  v1: {
    Auth,
  },
};
