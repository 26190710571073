import React, { useEffect, useState } from 'react';
import Header from '../sections/Header';
import Footer from '../sections/Footer';
import axios from 'axios';
// import { jwtDecode } from "jwt-decode";

// import iconsss
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import {
  faBell,
  faList,
  faTableCells,
} from '@fortawesome/free-solid-svg-icons';

// import { dynamicLinks } from './firebaseConfig';

// import { dynamicLinks } from "../firbase";

// import { shareslatefunRef } from "../firbase";

// firefirebase
// import firebase from "firebase/app";
// import "firebase/dynamic-links";

import avatarImg from '../assets/images/avatarImg.jpg';
// import { firebaseInstance } from "../firbase";
import { Link, useNavigate } from 'react-router-dom';
import DataList from '../commoncomponents/DataList';
import { PacmanLoader } from 'react-spinners';
// import { API } from "../api";
// import { createDynamicLink } from "firebase/dynamic-links";

// import GenerateDynamicLink from "../commoncomponents/DynamicLing";

const InviteUsersPage = () => {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);
  // selected users using checkbox
  const [selectedUsers, setSelectedUsers] = useState([]);
  //loader
  const [loading, setLoading] = useState(true);

  //listselected useState
  const [selectedList, setSelectedList] = useState(true);

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);

  // If the user has not token route to the sign in Page
  useEffect(() => {
    if (!token) {
      navigate('/signin');
    }
  }, [token, navigate]);

  const Url = 'https://apis.shareslate.fun';
  const api = axios.create({
    baseURL: `${Url}/api`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  useEffect(() => {
    const followingUserData = async () => {
      try {
        const response = await api.get(
          '/users/get_self_follower_folloing_list'
        );

        // console.log(
        //   "usersDatacallingggggggggg Follower user",
        //   response.data.get_following_user
        // );

        setUserData(response.data.get_following_user);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        // console.error("Error fetching data:", error);
      }
    };

    // Call followingusers function
    followingUserData();
    //Call Notification users function
  }, []); // Empty dependency array means this useEffect will run once when the component mounts
  useEffect(() => {
    NotificationUsersData(page);
  }, [page]);

  const postData = async (shortlink, ID) => {
    try {
      const apiUrl = `${Url}/api/minis/watch_party_invitation`;

      const requestBody = {
        users: selectedUsers,
        room: shortlink,
        room_code: ID,
        web_link: `http://localhost:3000/funparty?meet=${ID}`,
      };

      const response = await axios.post(apiUrl, requestBody, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      navigate(`/funparty?meet=${ID}`);
      // Handle the response data
      setData(response);
    } catch (err) {
      setError(err.message);
    }
  };

  const createDynamicLink = async (ID) => {
    const dynamicLinkDomain = 'https://funparty.page.link'; // Replace with your dynamic link domain
    const apiKey = 'AIzaSyAufZRyW96sn3Pps43TzYmnNJSb6iXK6YU';
    const apiUrl = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${apiKey}`;
    const requestData = {
      dynamicLinkInfo: {
        domainUriPrefix: dynamicLinkDomain,
        // link: "https://shareslate.fun/",
        link: `https://funparty.page.link/jofZ/?${ID}`,
        androidInfo: {
          androidPackageName: 'com.shareslatefunparty',
        },
        iosInfo: {
          iosBundleId: 'org.reactjs.native.example.shareslatefunparty',
        },
      },
    };
    try {
      const response = await axios.post(apiUrl, requestData);
      const shortLink = response.data.shortLink;
      console.log('Short Dynamic Link:', shortLink);
      postData(shortLink, ID);
      // return shortLink;
    } catch (error) {
      // console.error("Error generating Dynamic Link:", error);
    }
  };

  const generateRandomID = () => {
    const characters =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let strongId = '';

    for (let i = 0; i < 6; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      strongId += characters.charAt(randomIndex);
    }

    createDynamicLink(strongId);
  };

  const toggleSelectUser = (userId) => {
    setSelectedUsers((prevSelectedUsers) =>
      prevSelectedUsers.includes(userId)
        ? prevSelectedUsers.filter((id) => id !== userId)
        : [...prevSelectedUsers, userId]
    );
  };
  // console.log("selected users", selectedUsers);

  // Filtered FollowerUsers
  const [searchTerm, setSearchTerm] = useState('');

  const filteredUsers = userData.filter((user) => {
    const fullName = `${user.first_name} ${user.last_name}`.toLowerCase();
    return fullName.includes(searchTerm.toLowerCase());
  });
  // console.log("🚀 ~ filteredUsers ~ filteredUsers:", filteredUsers);

  //________________________for selected of list view and Grid View fn__________________________________
  const gridView = () => {
    setSelectedList(false);
    setSelectedUsers([]);
  };

  const listedView = () => {
    setSelectedList(true);
    setSelectedUsers([]);
  };

  // ____________________Notification Data is handled here ______________________________

  const [notificationUsersData, setnotificationUsersData] = useState([]);

  const NotificationUsersData = async (page) => {
    try {
      const notificationUsersResponse = await api.get(
        `/notifications?limit=10&page=${page}`
      );

      const filteredArray =
        notificationUsersResponse?.data?.notifications?.filter(
          (obj) => obj.type === 'Watch_Party_Invitation'
        );

      setnotificationUsersData([...notificationUsersData, ...filteredArray]);

      setPage(page + 1);

      // setnotificationUsersData(notificationUsersResponse?.data?.notifications);
    } catch (error) {
      // alert("notification error", error);
      // console.error("Error Notification fetching data:", error);
    }
  };

  // console.log("NotificationsUser varrrrr:", notificationUsersData);

  // show notification data on clicking notification icon
  const [showDataList, setShowDataList] = useState(false);

  const handleNotificationClick = () => {
    setShowDataList(!showDataList);
  };

  // Token Expiration Codeeeee......
  // useEffect(() => {
  //   const checkTokenExpiration = () => {
  //     const token = localStorage.getItem("token");

  //     if (token) {
  //       const decodedToken = jwtDecode(token);
  //       console.log("🚀 ~ checkTokenExpiration ~ decodedToken:", decodedToken);
  //       const currentTime = Date.now() / 1000;
  //       console.log("🚀 ~ checkTokenExpiration ~ currentTime:", currentTime);
  //       console.log("kkkkkkkkkkkk", decodedToken.iat);
  //       if (decodedToken.iat < currentTime) {
  //         alert("Session has expired. Please log in again.");
  //         localStorage.removeItem("token");
  //         navigate("/signin");
  //       }
  //     }
  //   };

  //   checkTokenExpiration();

  //   const interval = setInterval(checkTokenExpiration, 120000);

  //   return () => clearInterval(interval);
  // }, []);

  //setTimeout(checkTokenExpiration, 120000);

  // show modal....
  // const [showModal, setShowModal] = useState(false);

  // useEffect(() => {
  //   // Show modal when component mounts
  //   setShowModal(true);

  //   // You may want to clean up the effect when the component unmounts
  //   return () => {
  //     // Clean up code here if necessary
  //   };
  // }, []);

  // const handleCloseModal = () => {
  //   setShowModal(false);
  // };

  // const handleModalClick = (e) => {
  //   // Prevent closing modal if the click is inside the modal content
  //   e.stopPropagation();
  // };

  return (
    <>
      <Header />

      {/* show modal */}

      {/* {showModal && (
        <div className="modal" onClick={handleCloseModal}>
          <div className="modal-content" onClick={handleModalClick}>
            <span className="close" onClick={handleCloseModal}>
              &times;
            </span>
            <p>Modal content goes here...</p>
          </div>
        </div>
      )} */}

      {/* show modal end */}

      <div className="w-[80%] rounded-xl border shadow-xl hover:shadow-2xl mx-auto md:py-5 my-6 relative">
        <button
          onClick={handleNotificationClick}
          className="absolute md:right-5 right-0 top-[-3.5rem] md:top-5"
        >
          <FontAwesomeIcon className="text-3xl text-blue-600" icon={faBell} />
        </button>
        {showDataList && (
          <DataList data={notificationUsersData} navigate={navigate} />
        )}
        <div className="w-full mt-20 md:mt-0 md:flex justify-between md:w-[70%] mx-auto my-10 px-10 py-4 rounded-full  bg-[#5E72E4]">
          <input
            type="text"
            placeholder="search by name"
            className="border p-2 px-4 mb-4 md:mb-0 md:w-[50%] outline-none rounded-full"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          {selectedUsers.length === 0 ? (
            ''
          ) : (
            <button
              // onClick={postData}
              onClick={generateRandomID}
              className=" border-2 border-[#fff] ml-2 ml:0  uppercase font-semibold px-4 py-2 rounded-lg text-white hover:bg-transparent"
            >
              {/* <Link to="/funparty">Invite Selected User</Link> */}
              <Link>Invite Selected User</Link>
            </button>
          )}
        </div>
        <div className="flex justify-center gap-3">
          <p
            onClick={listedView}
            className={`${
              selectedList ? 'text-[#5E72E4] text-3xl' : ''
            } cursor-pointer text-2xl`}
          >
            <FontAwesomeIcon icon={faList} />
            {/* ListView */}
          </p>
          <p
            onClick={gridView}
            className={`${
              selectedList ? '' : 'text-[#5E72E4] text-3xl'
            } cursor-pointer text-2xl`}
          >
            <FontAwesomeIcon icon={faTableCells} />
            {/* GridView */}
          </p>
        </div>

        {loading ? (
          <p className="flex justify-center mt-12">
            <PacmanLoader color="#5E72E4" />
          </p>
        ) : (
          <div>
            {selectedList ? (
              <div className="flex justify-center w-full md:w-[70%] mx-auto gap-3 md:gap-5 flex-col p-5">
                {filteredUsers.length > 0 &&
                  filteredUsers.map((item) => {
                    return (
                      <div
                        className="p-2 rounded-2xl border border-[#5E72E4] flex items-center justify-between"
                        key={item._id}
                      >
                        <div className="flex md:gap-3 gap-1 items-center">
                          <div className="w-[40px] h-[40px] md:w-[70px] md:h-[70px]  rounded-full mb-5">
                            <img
                              className="w-[100%] mt-2 h-[100%] object-cover rounded-full"
                              src={
                                item.profile_image
                                  ? item.profile_image
                                  : avatarImg
                              }
                              // src={avatarImg}
                              alt="usersImg"
                            />
                          </div>

                          <div>
                            <p className="text-[10px] md:text-[1rem] font-semibold">
                              {' '}
                              <span>{item.first_name}</span>{' '}
                              <span>{item.last_name}</span>
                            </p>
                          </div>
                        </div>

                        <div className="mb-4 flex items-center bg-[#5E72E4] md:p-3 p-2 rounded-full  text-white mt-4">
                          <input
                            type="checkbox"
                            id={`inviteCheckbox-${item._id}`}
                            className="rounded border-gray-300 focus:border-indigo-500 focus:ring-indigo-200 text-indigo-600"
                            onChange={() => toggleSelectUser(item._id)}
                          />
                          <label
                            htmlFor={`inviteCheckbox-${item._id}`}
                            className="ml-2 text-[10px] md:text-[1rem] font-semibold"
                          >
                            Invite User
                          </label>
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              //grid view ....
              <div className="flex justify-center gap-10 flex-wrap p-5">
                {filteredUsers.length > 0 &&
                  filteredUsers.map((item) => {
                    return (
                      <div
                        className="p-5 rounded-2xl border border-[#5E72E4] flex flex-col grow basis-[300px] items-center justify-center"
                        key={item._id}
                      >
                        <div className="mb-6 flex items-center bg-[#5E72E4]  p-2 rounded-full  text-white mt-4">
                          <input
                            type="checkbox"
                            id={`inviteCheckbox-${item._id}`}
                            className="rounded border-gray-300 focus:border-indigo-500 focus:ring-indigo-200 text-indigo-600"
                            onChange={() => toggleSelectUser(item._id)}
                          />
                          <label
                            htmlFor={`inviteCheckbox-${item._id}`}
                            // className="ml-2 text-lg font-semibold text-gray-500"
                            className="ml-2 text-[10px] md:text-[1rem] font-semibold"
                          >
                            Invite User
                          </label>
                        </div>
                        <div className="w-[60px] h-[60px] md:w-[100px] md:h-[100px]  rounded-full mb-5">
                          <img
                            className="w-[100%] h-[100%] object-cover rounded-full"
                            src={
                              item.profile_image
                                ? item.profile_image
                                : avatarImg
                            }
                            // src={avatarImg}
                            alt="usersImg"
                          />
                        </div>

                        <p className="text-[10px] md:text-[1rem] font-semibold">
                          {' '}
                          <span>{item.first_name}</span>{' '}
                          <span>{item.last_name}</span>
                        </p>
                      </div>
                    );
                  })}
              </div>
            )}

            {/* {filteredUsers.length === 0 && userData.length === 0 && (
              <p>No Search Found!</p>
            )} */}

            {userData.length === 0 ? (
              <div className="flex items-around justify-center h-[400px]">
                <p className="colorChange text-[10px] md:text-xl lg:text-3xl font-semibold capitalize">
                  Install the ShareSlate Fun App and make friends to Enjoy
                  FunParty
                </p>
              </div>
            ) : (
              ''
            )}
          </div>
        )}

        {/* <h1 className="text-5xl mb-10">
          another checking purposes...........................
        </h1>

        <div className="w-[70%] border-1 border-green-500 mx-auto my-6 ">
          {filteredUsers.length > 0 ? (
            selectedList ? (
              filteredUsers.map((item) => {
                return (
                  <div
                    className="p-2 rounded-2xl border border-[#5E72E4] flex items-center justify-between"
                    key={item._id}
                  >
                    <div className="flex gap-3 items-center">
                      <div className="w-[70px] h-[70px]  rounded-full mb-5">
                        <img
                          className="w-[100%] mt-2 h-[100%] object-cover rounded-full"
                          src={
                            item.profile_image ? item.profile_image : avatarImg
                          }
                          // src={avatarImg}
                          alt="usersImg"
                        />
                      </div>

                      <div>
                        <p className="text-md font-bold">
                          {" "}
                          <span>{item.first_name}</span>{" "}
                          <span>{item.last_name}</span>
                        </p>
                      </div>
                    </div>

                    <div className="mb-5 bg-[#5E72E4] p-3 rounded-full  text-white mt-4">
                      <input
                        type="checkbox"
                        id={`inviteCheckbox-${item._id}`}
                        className="rounded border-gray-300 focus:border-indigo-500 focus:ring-indigo-200 text-indigo-600"
                        onChange={() => toggleSelectUser(item._id)}
                      />
                      <label
                        htmlFor={`inviteCheckbox-${item._id}`}
                        className="ml-2 text-md font-semibold"
                      >
                        Invite User
                      </label>
                    </div>
                  </div>
                );
              })
            ) : (
              filteredUsers.map((item) => {
                return (
                  <div
                    className="p-5 rounded-2xl border flex flex-col items-center justify-center"
                    key={item._id}
                  >
                    <div className="mb-5">
                      <input
                        type="checkbox"
                        id={`inviteCheckbox-${item._id}`}
                        className="rounded border-gray-300 focus:border-indigo-500 focus:ring-indigo-200 text-indigo-600"
                        onChange={() => toggleSelectUser(item._id)}
                      />
                      <label
                        htmlFor={`inviteCheckbox-${item._id}`}
                        className="ml-2 text-lg font-semibold text-gray-500"
                      >
                        Invite User
                      </label>
                    </div>
                    <div className="w-[100px] h-[100px]  rounded-full mb-5">
                      <img
                        className="w-[100%] h-[100%] object-cover rounded-full"
                        src={
                          item.profile_image ? item.profile_image : avatarImg
                        }
                        // src={avatarImg}
                        alt="usersImg"
                      />
                    </div>

                    <p className="text-lg font-bold">
                      {" "}
                      <span>{item.first_name}</span>{" "}
                      <span>{item.last_name}</span>
                    </p>
                  </div>
                );
              })
            )
          ) : (
            <div className="flex justify-center items-center h-96">
              <p className="text-lg text-gray-500">
                To use this feature, please install the Shareslatefun app and
                follow some users.
              </p>
            </div>
          )}
        </div> */}
      </div>

      {/* <div>
        <h1>POST API Request with Axios</h1>
        <button >Send POST Request</button>
        {data && <pre>{JSON.stringify(data, null, 2)}</pre>}
        {error && <p>Error: {error}</p>}
      </div> */}

      <Footer />
    </>
  );
  console.log('🚀 ~ InviteUsersPage ~ selectedUsers:', selectedUsers);
  console.log('🚀 ~ InviteUsersPage ~ selectedUsers:', selectedUsers);
};

export default InviteUsersPage;
