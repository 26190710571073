import React from 'react';

import avatarImg from '../assets/images/avatarImg.jpg';
// import { Link } from "react-router-dom";

const DataList = ({ data, navigate }) => {
  return (
    <ul className="absolute right-0 top-0 md:top-14 rounded-2xl bg-[#5E72E4] text-white md:w-[550px] p-5 h-auto">
      {data.map((item) => (
        <li
          className={`flex justify-between items-center rounded-lg p-2 gap-4 mt-3 border bg-[white]
            // item?.is_read ? "bg-[white]" : "bg-[#000]"
          `}
          key={item?._id}
        >
          {/* item?.is_read ? "bg-[#94ccd3]" : "bg-[#000]" */}
          <div className="flex gap-2 items-center">
            <img
              className="w-[40px] h-[30px] md:w-[50px] md:h-[50px] rounded-full border"
              src={
                item?.from?.profile_image
                  ? item?.from?.profile_image
                  : avatarImg
              }
              alt="profileImg"
            />

            <p className="text-[10px] md:text-sm text-[#000]">{`${
              item?.from?.first_name +
              ' ' +
              item?.from?.last_name +
              ' ' +
              item?.body
            }`}</p>
          </div>

          <p>
            {item?.is_room_expired ? (
              <button
                disabled
                className="py-1 px-2 md:py-2 md:px-4 bg-[gray] outline-none rounded-lg"
              >
                Expired
              </button>
            ) : (
              // <Link to={item?.room_code}>
              <button
                onClick={() => {
                  navigate(`/funparty?meet=${item?.room_code}`);
                }}
                className="py-1 px-2 md:py-2 md:px-4 bg-[#5E72E4] outline-none rounded-lg"
              >
                Join
              </button>
              // </Link>
            )}
          </p>
        </li>
      ))}
    </ul>
  );
};

export default DataList;
