import React, { useState } from 'react';
import Header from '../sections/Header';
import Footer from '../sections/Footer';
// import axios from "axios";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const SignUp = () => {
  const [userExist] = useState('');

  const navigate = useNavigate();

  localStorage.removeItem('token');
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    number: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Implement backend logic for sign-up here

    try {
      const signupUser = await axios.post(
        'https://apis.shareslate.fun/api/users/signup',
        {
          step: 'first',
          first_name: formData?.firstname,
          last_name: formData?.lastname,
          email: formData?.email,
          password: formData?.password,
          phone_no: formData?.number,
          your_interests: ['bio', 'fantasy'],
        }
      );
      localStorage.setItem('email', formData?.email);
      // console.log("🚀 ~ handleSubmit ~ signupUser:", signupUser);
      navigate('/otp');
      // console.log("Sign Up:", formData);
    } catch (err) {
      // console.log("Error", err.response.data.message);

      alert(err.response.data.message);
      // console.log("🚀 ~ handleSubmit ~ err:", err.response);
      // setTimeout(() => {
      //   setuserExist(err.response.data.message);
      //   console.log(userExist);
      //   console.log("Hello World from Codecademy");
      // }, 2000);
    }
  };

  return (
    <div className="h-screen flex flex-col justify-between ">
      <Header />
      <div className="md:w-[55%] w-[95%] mx-auto border px-5 py-10 rounded-lg my-5">
        <h2 className="text-2xl text-center uppercase font-bold text-[#5E72E4]">
          Sign Up
        </h2>
        {userExist && <p className="text-red-600 text-lg">User alredy Exist</p>}
        <form className="flex flex-col gap-8 mt-7" onSubmit={handleSubmit}>
          <div className="flex flex-wrap gap-8 md:gap-3">
            <input
              className="px-3 grow py-4 outline-none rounded-full border "
              type="text"
              name="firstname"
              placeholder="First Name"
              value={formData.firstname}
              onChange={handleChange}
              required
            />
            <input
              className="px-3 grow py-4 outline-none rounded-full border "
              type="text"
              name="lastname"
              placeholder="Last Name"
              value={formData.lastname}
              onChange={handleChange}
              required
            />
          </div>

          <input
            className="px-3 py-4 outline-none rounded-full border "
            type="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <input
            className="px-3 py-4 outline-none rounded-full border "
            type="number"
            name="number"
            placeholder="Phone No"
            value={formData.number}
            onChange={handleChange}
            required
          />
          <input
            className="px-3 py-4 outline-none rounded-full border "
            type="password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            required
          />

          <button
            className="py-4 text-white border bg-[#5E72E4] rounded-full "
            type="submit"
          >
            Sign Up
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default SignUp;
