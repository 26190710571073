import React from 'react';

// import shareslateVideo from "../assets/video/heroVideo.mp4";

// import heroImg from "../assets/images/hero.png";
import googlePlay from '../assets/images/googleplay.png';
import appstorePlay from '../assets/images/appstoreplay.png';
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <section className="w-[100%] py-[5rem]">
      <div className="container w-[80%] mx-auto">
        <div className="md:flex md:flex-row heroResponsive">
          <div className="basis-[60%] p-5 flex flex-col justify-center items-center">
            <h3 className="text-[#333333] leading-[3rem] font-bold sm:mt-20 w-[300px] text-[3rem] text-center">
              Create. Post. Have Fun.
            </h3>
            <p className="mt-4 text-[.8rem] md:w-[70%] text-center text-[#333333]">
              Unleash your creativity through videos and minis, showcasing your
              unique expression. Back your favorite creators and open doors to
              exciting opportunities for growth.
            </p>

            <div className="flex items-center gap-5 mt-10 sm:mt-16">
              <Link
                to="https://play.google.com/store/apps/details?id=com.shareslatefun&pcampaignid=web_share"
                target="_blank"
              >
                <img
                  // width={140}
                  className="w-[100px] sm:w-[140px]"
                  src={googlePlay}
                  alt="googlePlay"
                />
              </Link>
              <Link
                to="https://apps.apple.com/us/app/share-slate-fun/id1670628391"
                target="_blank"
              >
                <img
                  // width={140}
                  className="w-[100px] sm:w-[140px]"
                  src={appstorePlay}
                  alt="appstorePlay"
                />
              </Link>
            </div>
          </div>
          <div className="mt-3 basis-[40%] md:flex md:justify-center flex justify-center items-center">
            {/* <img className="" src={heroImg} alt="heroImg" /> */}
            <video
              className="h-[470px]  bg-[#000] py-6 w-[250px] rounded-2xl"
              controls
              autoPlay="autoplay"
              // muted="muted"
              loop="loop"
              preload="auto"
              // playsInline="playsinline"
            >
              <source
                // src={shareslateVideo}
                src={
                  'https://shareslatelogoandstuff.s3.amazonaws.com/intro.mp4'
                }
                // src={require("../assets/video/heroVideo.mp4").default}
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
