import React from "react";
import ImgDiv from "../commoncomponents/ImgDiv";
import ContentDiv from "../commoncomponents/ContentDiv";

import usersImg from "../assets/images/profileImg.png";
import arrowImg from "../assets/images/arrowimg.png";

const AnalyzeUsers = () => {
  return (
    <section className="w-[100%] py-10">
      <div className="w-[80%] mx-auto  container">
        <div className="flex flex-col sm:flex sm:flex-row">
          <ImgDiv Img={usersImg} />
          <ContentDiv
            h2="Analyze and understand your users. "
            list1="Analyze the user behavor and where you are getting your views from."
            list2="Analytics helps you create content based on where your 
            viewer group demographics."
            arrowImg={arrowImg}
          />
        </div>
      </div>
    </section>
  );
};

export default AnalyzeUsers;
