import React, { useState } from 'react';
// import Header from "../sections/Header";
// import Footer from "../sections/Footer";
import { JitsiMeeting } from '@jitsi/react-sdk';
import { useLocation, useNavigate } from 'react-router-dom';

// import funLogo from "../assets/images/googleplay.png";

// import Loader from "../commoncomponents/Loader";
import axios from 'axios';

const FunpartyPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  // const history = useHistory();
  // const [thankYouMessage, setThankYouMessage] = useState("");
  const searchParams = new URLSearchParams(useLocation().search);
  const ID = searchParams.get('meet');
  // const roomIDtoEnd = `http://localhost:3000/funparty?meet=${ID}`;
  const Url = 'https://apis.shareslate.fun';
  const token = localStorage.getItem('token');
  // Handle Meeting End
  const handleMeetingEnd = async () => {
    try {
      const meetendUrl = `${Url}/api/minis/expire_room`;
      const requestBody = { room: ID };

      const response = await axios.post(meetendUrl, requestBody, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // setThankYouMessage("Thank you for joining the meeting!");
      console.log('🚀 ~ handleMeetingEnd ~ response:', response);
      navigate('/inviteusers');
    } catch (error) {
      console.log('error message', error);
    }
  };

  return (
    <>
      {/* <Header /> */}
      {/* <div className="spinner">Loading...</div> */}
      {/* <Loader /> */}
      {isLoading && (
        <div className="w-[100%] h-screen flex items-center justify-center text-2xl">
          Wait, Your Meet is Loading...
        </div>
      )}
      <JitsiMeeting
        // domain={YOUR_DOMAIN}
        // domain="meet.jit.si"
        domain="meet.shareslate.fun/"
        // token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiI4QjIzQTRCQTg1REU4NUQyOTIyNzAzRjMxOTQ5NjkzNCIsImlzcyI6IjhCMjNBNEJBODVERTg1RDI5MjI3MDNGMzE5NDk2OTM0Iiwic3ViIjoiKiIsInJvb20iOiIqIiwiaWF0IjoxNzAxMTA4ODA3LCJuYmYiOjE3MDEwOTk3MjAsImV4cCI6MTc0MTgwODUyMH0.VuPKduPs0droOLlH05w9QeL9ZNdEDyWmeSnTFzaXcJQ"
        // serverURL="https://meet.shareslate.fun/"
        // lang=""
        // serverURL={"https://meet.shareslate.fun/"}
        roomName={ID}
        jwt={
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiI4QjIzQTRCQTg1REU4NUQyOTIyNzAzRjMxOTQ5NjkzNCIsImlzcyI6IjhCMjNBNEJBODVERTg1RDI5MjI3MDNGMzE5NDk2OTM0Iiwic3ViIjoiKiIsInJvb20iOiIqIiwiaWF0IjoxNzAxMTA4ODA3LCJuYmYiOjE3MDEwOTk3MjAsImV4cCI6MTc0MTgwODUyMH0.VuPKduPs0droOLlH05w9QeL9ZNdEDyWmeSnTFzaXcJQ'
        }
        configOverwrite={{
          // startWithAudioMuted: true,
          // disableModeratorIndicator: true,
          // startScreenSharing: true,
          // enableEmailInStats: false,
          // SHOW_JITSI_WATERMARK: false,
          // hideLogo: false,
          defaultLogoUrl:
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRD0SG0P-maEL18USKddIL616I4IWbHEETyw6T5ywl1bA&s',
          subject: 'lalalala',
          logoClickUrl: 'https://example-company.org',
          hideConferenceSubject: false,
        }}
        interfaceConfigOverwrite={{
          DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
          DEFAULT_LOGO_URL:
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRD0SG0P-maEL18USKddIL616I4IWbHEETyw6T5ywl1bA&s',
          DEFAULT_LANGUAGE: 'en',

          APP_NAME: 'FUN Meet',
        }}
        // configOverwrite={{
        //   // SHOW_JITSI_WATERMARK: false,
        //   // logoImageUrl: "../assets/images/googleplay.png",
        //   // SHOW_JITSI_WATERMARK: false,
        //   //defaultLogoUrl: "../assets/images/googleplay.png",
        //   // DEFAULT_WELCOME_PAGE_LOGO_URL: "../assets/images/googleplay.png",
        //   // DEFAULT_WELCOME_PAGE_LOGO_URL: "jawad",
        // }}
        // interfaceConfigOverwrite={{
        //   // hideLogo: false,
        //   SHOW_JITSI_WATERMARK: false, // Hide Jitsi watermark
        // }}
        userInfo={{
          displayName: 'You',
        }}
        // spinner={SpinnerView}
        onApiReady={(externalApi) => {
          setTimeout(() => {
            setIsLoading(false);
          }, 2000);
          // setIsLoading(false);
          // here you can attach custom event listeners to the Jitsi Meet External API
          // you can also store it locally to execute commands
        }}
        getIFrameRef={(iframeRef) => {
          iframeRef.style.height = '100vh';
        }}
        onReadyToClose={handleMeetingEnd}
      />
      {/* {thankYouMessage && (
        <div className="text-center mt-4">{thankYouMessage}</div>
      )} */}
      {/* <Footer /> */}
    </>
  );
};

export default FunpartyPage;
