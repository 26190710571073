import React from "react";

import CommonHeader from "../commoncomponents/CommonHeader";
import Footer from "../sections/Footer";
import funlandinglogo from "../assets/images/funlandinglogo.png";

const ContactUs = () => {
  return (
    <>
      <CommonHeader funlandinglogo={funlandinglogo} pageName="|  CONTACT US" />
      <div className="w-[80%] mx-auto mb-32 mt-16">
        <h2 className="text-xl font-semibold ">Customer Support</h2>
        <p className="mt-5">
          If you encounter any problems when using Share Slate, please write to
          our team at contact@shareslate.fun with your username, indicating the
          issue with relative screenshots if possible. Once we receive your
          email, we will get back to you as soon as we possibly can.
        </p>
        <p className="mt-2">
          <span className="font-semibold">Email:</span>{" "}
          <a
            href="mailto:contact@shareslate.fun"
            className="text-blue-400 underline"
          >
            contact@shareslate.fun
          </a>
        </p>

        <h2 className="text-xl font-semibold mt-10">Feedback</h2>
        <p className="mt-5">
          If you have any suggestion or feedback when you use Share Slate,
          contact us at anytime.
        </p>
        <p className="mt-2">
          <span className="font-semibold">Email:</span>{" "}
          <a
            href="mailto:contact@shareslate.fun"
            className="text-blue-400 underline"
          >
            contact@shareslate.fun
          </a>
        </p>

        <h2 className="text-xl font-semibold mt-10">Business Cooperation</h2>
        <p className="mt-5">
          Get in touch with Share Slate if you have any need for business
          cooperation.
        </p>
        <p className="mt-2">
          <span className="font-semibold">Email:</span>{" "}
          <a
            href="mailto:contact@shareslate.fun"
            className="text-blue-400 underline"
          >
            contact@shareslate.fun
          </a>
        </p>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
