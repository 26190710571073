import React from "react";
import CommonHeader from "../commoncomponents/CommonHeader";
import Footer from "../sections/Footer";
import funlandinglogo from "../assets/images/funlandinglogo.png";

const CopyRightPolicy = () => {
  return (
    <>
      <CommonHeader
        funlandinglogo={funlandinglogo}
        pageName="|  COPYRIGHT POLICY"
      />
      <div className="w-[90%] ml-auto mr-auto my-10  rounded-lg p-[2rem]">
        <section>
          <p>
            Share Slate respects the intellectual property rights of others, and
            we expect you to do the same. Share Slate’s 
            <a href="/terms" className="text-blue-400 underline">
              Terms & Conditions
            </a>
             and{" "}
            <a href="/communityguidelines" className="text-blue-400 underline">
              Community Guidelines
            </a>
              do not allow posting, sharing, or sending any content that
            violates or infringes someone else’s copyrights, trademarks or other
            intellectual property rights.
          </p>
          <h2 className="text-lg  font-medium my-4">Copyright</h2>
          <p>
            Copyright is a legal right that protects original works of
            authorship (e.g., music, videos, etc.). Generally, copyright
            protects an original expression of an idea but does not protect
            underlying ideas or facts.
          </p>

          <h3 className="text-lg  font-medium my-4">COPYRIGHT INFRINGEMENT</h3>
          <p>
            We do not allow any content that infringes copyright. Any use of
            copyrighted content of others without proper authorization or
            legally valid reason may lead to a violation. In some cases, you may
            be required to provide evidence to prove that you are entitled to
            use copyrighted content of others.
          </p>

          <h3 className="text-lg  font-medium my-4">
            REMOVAL OF CONTENT; SUSPENSION OR TERMINATION OF ACCOUNT
          </h3>
          <p>
            Any user content that infringes another person’s copyright may be
            removed. Your account may be suspended or terminated for multiple
            copyright violations in connection with your use of the Share Slate
            site or app, or other violations of the{" "}
            <a href="/terms" className="text-blue-400 underline">
              Terms & Conditions
            </a>
             and{" "}
            <a href="/communityguidelines" className="text-blue-400 underline">
              Community Guidelines
            </a>
          </p>

          <h3 className="text-lg  font-medium my-4">
            COPYRIGHT INFRINGEMENT NOTIFICATION
          </h3>
          <p>
            If you believe that your copyright-protected work was posted on
            Share Slate’s site or app without authorization, you may submit a
            copyright infringement notification. The notification must include:
          </p>
          <ul
            className="ml-5 my-2"
            style={{
              listStyle: "inside",
              listStyleType: "initial",
            }}
          >
            <li>
              Email address, physical address, and phone number for contact.
            </li>
            <li>A clear and complete description of your work.</li>
            <li>
              URL or other identifying location of the allegedly infringing
              work.
            </li>
            <li>A statement of good faith belief in unauthorized use.</li>
            <li>
              A statement, under penalty of perjury, that the information is
              accurate, and you are the copyright owner or authorized to act on
              their behalf.
            </li>
            <li>
              The Physical or electronic signature of the copyright owner or an
              authorized representative of the copyright owner. To satisfy this
              requirement, you may type your full legal name (not that of a
              company) at the bottom of your notification.
            </li>
          </ul>
          <p className=" font-medium my-4">
            Submit the above information to us:
          </p>
          <ul
            className="ml-5 my-2"
            style={{
              listStyle: "inside",
              listStyleType: "initial",
            }}
          >
            <li>
              By Email:{" "}
              <a
                href="mailto:report@shareslate.fun"
                className="text-blue-400 underline"
              >
                report@shareslate.fun
              </a>
            </li>
            <li>
              By Mail (United States): DMCA Notice Department, Music Reports,
              Inc., 21122 Erwin St., Woodland Hills, CA 91367, +1 818 558 1400.
            </li>
          </ul>
          <p>
            All information submitted in your copyright infringement
            notification may be forwarded to the Share Slate, team or otherwise
            made public by Share Slate or a third party.
          </p>
          <p>
            Before submitting a notification, please be aware of that
            intentionally submitting a misleading or fraudulent report may lead
            to liability for damages under section 512(f) of the United States
            Digital Millennium Copyright Act (DMCA) or similar laws in other
            countries.
          </p>
        </section>

        <section>
          <h2 className="text-lg  font-medium my-4">
            COPYRIGHT INFRINGEMENT COUNTER-NOTIFICATION
          </h2>
          <p>
            If you receive a copyright infringement notification that you
            believe to be in error, you may provide us with a
            counter-notification. The counter-notification must include the
            following:
          </p>
          <ul
            className="ml-5 my-2"
            style={{
              listStyle: "inside",
              listStyleType: "initial",
            }}
          >
            <li>
              Your contact information, including your full legal name (not that
              of a company), an email address, a physical address, and a phone
              number. If you are an authorized representative of the uploader,
              such as an attorney, please be sure to specify your relationship
              to the uploader. Identify the location, including any URL, of the
              work that has been removed or disabled.
            </li>
            <li>
              You must agree to and include the following statement: "I consent
              to the jurisdiction of the Federal District Court for the district
              in which my address is located, or if my address is outside of the
              United States, the judicial district in which Share Slate is
              located, and will accept service of process from the claimant."
            </li>
            <li>
              And the following statement: "I swear, under penalty of perjury,
              that I have a good faith belief that the material was removed or
              disabled as a result of a mistake or misidentification of the
              material to be removed or disabled."
            </li>
            <li>
              Your physical or electronic signature. To satisfy this
              requirement, you may type your full legal name (not that of a
              company) at the bottom of your electronic counter-notification.
            </li>
          </ul>
          <p>Submit the above information to us:</p>
          <ul
            className="ml-5 my-2"
            style={{
              listStyle: "inside",
              listStyleType: "initial",
            }}
          >
            <li>
              By Email:{" "}
              <a
                href="mailto:report@shareslate.fun"
                className="text-blue-400 underline"
              >
                : report@shareslate.fun
              </a>
            </li>
            <li>
              By Mail: If you reside in the United States, please submit the 
              above information to: DMCA Notice Department, Music Reports, Inc.,
              21122  Erwin St., Woodland Hills, CA 91367, +1 818 558 1400. 
            </li>
          </ul>
          <p>
            The counter-notification process may take no less than 10 business
            days to complete, please be patient. During this time, the copyright
            claimant may file an action seeking a court order to keep the
            content down. Please note that we will forward the entire
            counter-notification to the copyright claimant, including any
            personal information you provide, and the counter-notification may
            be made public. The claimant may use this information to file a
            lawsuit against you.
          </p>
          <p>
            If we do not receive notice within 10 business days that the
            original reporter is seeking a court order to prevent further
            infringement of the material at issue, we may replace or cease
            disabling access to the material that was removed. The decision to
            re-post any material is at Share Slate’s sole discretion.
          </p>
        </section>

        <section>
          <h2 className="text-lg  font-medium my-4">Trademark</h2>
          <p>
            A trademark is a word, symbol, slogan, design, or combination of any
            of the foregoing that identifies the source of a product or service
            and distinguishes it from other products or services.
          </p>
          <p>
            Share Slate’s policies prohibit any content that infringes on
            another person’s trademark. Using another person’s trademark in a
            way that may mislead or confuse people to believe that you are
            affiliated with the trademark owner may be a violation of our
            trademark policy.
          </p>
          <p>
            However, using another person’s trademark for the purpose of
            accurately referencing the trademark owner’s products or services,
            or to compare them to other products or services, is generally not
            considered a violation of our trademark policy.
          </p>

          <h3 className="text-lg  font-medium my-4">
            REMOVAL OF CONTENT; SUSPENSION OR TERMINATION OF ACCOUNT
          </h3>
          <p>
            Any user content that infringes another person’s trademark may be
            removed. Your account may be suspended or terminated for multiple
            trademark violations in connection with your use of the Share Slate
            site or app, or other violations of the{" "}
            <a href="/terms" className="text-blue-400 underline">
              Terms & Conditions
            </a>
             and{" "}
            <a href="/communityguidelines" className="text-blue-400 underline">
              Community Guidelines
            </a>
            .
          </p>

          <h3 className="text-lg  font-medium my-4">
            TRADEMARK INFRINGEMENT NOTIFICATION
          </h3>
          <p>
            Share Slate may investigate reports submitted by the trademark
            holder or their authorized representative. You can submit a
            trademark report through email (
            <a
              href="mailto:report@shareslate.fun"
              className="text-blue-400 underline"
            >
              report@shareslate.fun
            </a>
            ) with the following information:
          </p>
          <ul
            className="ml-5 my-2"
            style={{
              listStyle: "inside",
              listStyleType: "initial",
            }}
          >
            <li>
              Contact information, including full name, physical address, email
              address, and phone number.
            </li>
            <li>Your relationship to the trademark owner.</li>
            <li>
              A clear and complete description of your trademark that you
              believe has been infringed, including the jurisdiction of
              registration, registration number, trademarked goods and services
              class.
            </li>
            <li>
              A scan of trademark registration certificate or a direct link
              (URL) to your trademark record.
            </li>
            <li>
              Information about the allegedly infringing content, including the
              URL or other identifying location of the allegedly infringing
              content, and a description of how the content has been infringing
              your trademark.
            </li>
            <li>
              A statement that you have a good faith belief that use of the
              trademark described above, in the manner complained of, is not
              authorized by the trademark owner, its agent, or the law
            </li>
            <li>
              A statement, made under penalty of perjury, that the above
              information is accurate, and that you are the trademark owner or
              are authorized to act on behalf of the owner.
            </li>
            <li>
              The physical or electronic signature of the trademark owner or an
              authorized representative of the trademark owner.
            </li>
          </ul>
          <p>
            If we remove content in response to a report of trademark
            infringement, we may notify the person you reported to inform them
            the content was removed. We may also provide them with your contact
            information, including your email address and the name of the
            trademark owner, and/or details of your report.
          </p>
        </section>

        <section>
          <h2 className="text-lg  font-medium my-4">General Note</h2>
          <p>
            As a Share Slate user, you are responsible for the content you post.
            If you have questions about copyright law or trademark law, such as
            questions about whether your content or your use of another person’s
            name or brand infringes or otherwise violates another person’s
            rights, you may want to contact an attorney. If you are unsure
            whether the material you plan to report to us is infringing or
            otherwise violating another person’s right, you may also want to
            first seek legal advice before reporting such content to us.
          </p>
          <p>
            If you submit a report or infringement notification to us, we may
            contact you if we have additional questions about your report or
            notification. Please note that Share Slate is not in a position to
            adjudicate disputes between third parties, and may not be able to
            remove the content or suspend the account you reported for
            infringement or violation. As an alternative, you may want to
            contact the person who posted the content or owns the account to try
            to resolve your issue directly.{" "}
          </p>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default CopyRightPolicy;
