import React from "react";
import CommonHeader from "../commoncomponents/CommonHeader";
import Footer from "../sections/Footer";
import funlandinglogo from "../assets/images/funlandinglogo.png";

const CommunityGuidelines = () => {
  return (
    <>
      <CommonHeader
        funlandinglogo={funlandinglogo}
        pageName="|  COMMUNITY GUIDELINES"
      />
      <div className="w-[90%] ml-auto mr-auto my-10 rounded-lg p-[2rem]">
        <div>
          <h1 className="italic text-lg font-medium  my-4">
            Last Updated, November 22, 2023
          </h1>

          <p className="mb-4">
            Our Community Guidelines along with relevant portions of the Terms &
            Conditions (collectively, the "Guidelines") govern your relationship
            with and use of Share Slate and are enforced by us, the admins.
          </p>

          <p>
            Share Slate intends to use{" "}
            <strong className="font-bold">‘equal opportunity’</strong>
            algorithms to show ordinary, real, and diverse communities of people
            through the sharing of short and long videos and watch party also
            known as Fun Party. We are a{" "}
            <strong className="font-bold">platform of neutrality</strong> where
            users can converse and discover the world around them through
            content. We have created a platform to express yourself, share your
            life and show your talent. Our mission is to use technology to
            empower everyone's life so that everyone has the opportunity to
            show, the channel to speak, and the possibility to be seen. To
            accomplish this, we generally allow Share Slate users to moderate
            and curate their own content but also have set basic Guidelines to
            protect the safety and privacy of our users and others. To that end,
            our Guidelines are based on
            <strong className="font-bold">
              Equality, Unbiasedness, Respect, and Law.
            </strong>
          </p>

          <p className="mb-4">
            You must follow each of the below "Overarching Principles," as well
            as any other principles outlined in the Guidelines. Users who
            violate any of the Guidelines may be restricted, suspended, or
            otherwise banned, in our sole discretion. Again, our hope is that
            the Share Slate community is defined by you, but the Share Slate
            admins must reserve the right to revoke access or remove content for
            any reason, without notice, especially when it comes to the safety
            and privacy of our users and others.
          </p>

          <h2 className="text-xl  font-bold my-4">Overarching Principles</h2>

          <ol
            className="ml-5 my-2"
            style={{
              listStyle: "inside",
              listStyleType: "initial",
            }}
          >
            <li className="mt-3">
              <strong className=" font-medium underline italic my-4">
                No attacks on others:
              </strong>{" "}
              All Share Slate users are humans, which means they FEEL. We
              strictly do not allow harassment, bullying, identity-based
              attacks, racism, impersonation, or threats, advocation, or
              provocation of violence on our platform.
            </li>

            <li className="mt-3">
              <strong className=" underline font-medium italic my-4">
                No violations of privacy:
              </strong>{" "}
              You may not post others’ confidential, non-consensual, and/or
              sensitive details or impersonate others. Further, you may not post
              or threaten to post intimate or sexually-explicit media without
              the subject’s consent.
            </li>

            <li className="mt-3">
              <strong className=" underline font-medium italic my-4">
                No illegality or tortious activity:
              </strong>{" "}
              You may not post anything that is illegal, tortious, or that
              violates any law or regulation, nor may you use Share Slate to
              solicit or facilitate illegal, tortious, or prohibited activities
              or transactions, including but not limited to those involving
              controlled substances or contraband.
            </li>

            <li className="mt-3">
              <strong className=" underline font-medium italic my-4">
                No explicit or suggestive content involving minors:
              </strong>{" "}
              You may not post or encourage others to post sexual or suggestive
              content involving minors.
            </li>

            <li className="mt-3">
              <strong className="  underline font-medium italic my-4">
                No spamming, phishing, bots or malicious software:
              </strong>{" "}
              You may not post, distribute, facilitate, or use Share Slate in
              furtherance of phishing schemes, spam, malware, or other malicious
              code, nor may you use bots on Share Slate.
            </li>

            <li className="mt-3">
              <strong className=" underline font-medium italic my-4">
                No sexually explicit content or nudity:
              </strong>{" "}
              You may not post genitals, sexual acts or sex toys while using
              Share Slate.
            </li>
          </ol>

          <p>
            For additional details, please refer to Section 5 of the Terms &
            Conditions shown below:
          </p>

          <h2 className="text-lg  font-bold my-4">Enforcement</h2>

          <p>
            For more fairness and transparency, we have two ways ({" "}
            <strong className="text-lg font-bold mr-2">Enforcement A</strong>
            and <strong className="font-bold">Enforcement B</strong>) to enforce
            these Guidelines, in our discretion, which we’ll exercise based on
            the gravity of the violation:
          </p>

          <h3 className="text-xl  font-medium my-4">Enforcement A</h3>
          <ol>
            <li>1st. Warning, removal of content</li>
            <li>
              2nd. Temporary suspension of accounts for 5 days, removal of
              content
            </li>
            <li>
              3rd. Temporary suspension of accounts for 15 days, removal of
              content
            </li>
            <li>
              Final: Deletion of account, device ban fully revoking access to
              the Share Slate and its services as mentioned in
              <a href="/terms" className="text-blue-400 inline-block ml-2">
                Terms & Conditions
              </a>
              .
            </li>
          </ol>

          <h3 className="text-xl  font-medium my-4">Enforcement B</h3>
          <p>
            Deletion of account, device ban fully revoking access to the Share
            Slate and its services as mentioned in
            <a href="/terms" className="text-blue-400 inline-block ml-1">
              Terms & Conditions
            </a>{" "}
            .
          </p>
          {/* started from here */}
          <p>
            <strong className=" font-bold my-4">
              The specific enforcement according to each situation is as follows
            </strong>
          </p>
          <p>
            You may not violate the Community Guidelines, any applicable law,
            contract, intellectual property right, or other third-party right,
            nor commit any tortious acts, and you are solely responsible for
            your conduct while using our Services. In particular, you will not:
          </p>

          <ul
            className="ml-5 my-2"
            style={{
              listStyle: "inside",
              listStyleType: "initial",
            }}
          >
            <li>
              Engage in any harassing, threatening, intimidating, predatory, or
              stalking conduct;
            </li>
            <li>
              Use or attempt to use another user’s account without authorization
              from that user and Share Slate;
            </li>
            <li>
              Impersonate or post on behalf of any person or entity or otherwise
              misrepresent your affiliation with a person or entity;
            </li>
            <li>Sell, resell, or commercially use our Services;</li>
            <li>
              Copy, reproduce, distribute, publicly perform, or publicly display
              all or portions of our Services, except as expressly permitted by
              us or our licensors;
            </li>
            <li>
              Modify our Services, remove any proprietary rights notices or
              markings, or otherwise make any derivative works based upon our
              Services;
            </li>
            <li>
              Send, distribute, or post spam, unsolicited or bulk commercial
              electronic communications, chain letters, or pyramid schemes;
            </li>
            {/* <!-- ... (Other points from the provided content) ... --> */}
          </ul>
          <p>
            You also may not post or otherwise share User Content that is
            confidential and/or that you do not have all necessary rights to
            disclose. You may not create, post, store, or share any User Content
            that:
          </p>
          <ul
            className="ml-5 my-2"
            style={{
              listStyle: "inside",
              listStyleType: "initial",
            }}
          >
            <li>
              Is libelous, defamatory, indecent, lewd, suggestive, harassing,
              threatening, invasive of privacy or publicity rights, abusive,
              inflammatory;
            </li>
            <li>
              Contains or depicts any statements, remarks, or claims that do not
              reflect your honest views and experiences;
            </li>
            <li>
              Impersonates or misrepresents your affiliation with any person or
              entity;
            </li>
            <li>
              Contains any private or personal information of a third party
              without such third party’s consent;
            </li>
            <li>
              Contains any unsolicited promotions, political campaigning,
              advertising, or solicitations;
            </li>
            <li>
              May infringe any patent, trademark, trade secret, copyright, or
              other intellectual or proprietary right of any party; If reposted
              video being reported.
            </li>
            <li>
              The showcasing of marijuana and other illegal substances including
              paraphernalia is not allowed on Share Slate.
            </li>
          </ul>
          <p className="font-bold text-lg">
            For the above situation, we will implement Enforcement A.
          </p>

          {/* <h1 className="text-xl  font-medium my-4">Enforcement B</h1> */}

          <p>
            You may not violate the Community Guidelines, any applicable law,
            contract, intellectual property right, or other third-party right,
            nor commit any tortious acts, and you are solely responsible for
            your conduct while using our Services. In particular, you will not:
          </p>

          <ul
            className="ml-5 my-2"
            style={{
              listStyle: "inside",
              listStyleType: "initial",
            }}
          >
            <li>
              Be under 18 years of age to use Share Slate (or older if required
              by your country). If there is any violation, we will delete its
              account;
            </li>
            <li>
              Against the laws of the United States and shall not violate the
              community guidelines to ensure an orderly live broadcast
              environment;
            </li>
            <li>
              Use our Services other than for their intended purpose and in any
              manner that could interfere with, disrupt, negatively affect, or
              inhibit other users from fully enjoying our Services, or that
              could damage, disable, overburden, or impair the functioning of
              our Services in any manner;
            </li>
            <li>
              Reverse engineer any aspect of our Services or do anything that
              might discover source code or bypass or circumvent measures
              employed to prevent or limit access to any part of our Services;
            </li>
            <li>
              Use any data mining, robots, or similar data-gathering or
              extraction methods designed to scrape or extract data from our
              Services;
            </li>
            <li>
              Develop or use any applications that interact with our Services
              without our prior written consent;
            </li>
            <li>
              Bypass or ignore instructions contained in our robots.txt file; or
              Use our Services for any illegal or unauthorized purpose, or
              engage in, encourage, or promote any activity that violates these
              Terms and/or the Community Guidelines.
            </li>
          </ul>
          <p>
            You also may not post or otherwise share User Content that is
            confidential and/or that you do not have all necessary rights to
            disclose. You may not promote, create, post, store, or share any
            User Content that:
          </p>

          <ul
            className="ml-5 my-2"
            style={{
              listStyle: "inside",
              listStyleType: "initial",
            }}
          >
            <li>
              Is unlawful, obscene, nudity, pornographic, lewd, fraudulent,
              content that incites violence.
            </li>
            <li>
              Share or promote information (or synthetic or manipulated media)
              that is intended or likely to cause harm to any person or groups
              of people, including minors.
            </li>
            <li>
              Contains any viruses, corrupted data, or other harmful,
              disruptive, or destructive files or content; or
            </li>
            <li>
              In our sole judgment, is objectionable, restricts, or inhibits any
              other person from using or enjoying our Services, or may expose
              Share Slate or others to any harm or liability of any type.
            </li>
            <li>
              Would constitute, encourage, or provide instructions for a
              criminal offense, violate the rights of any party, or otherwise
              create liability or violate any local, state, national, or
              international law;
            </li>
            <li>
              May infringe any patent, trademark, trade secret, copyright, or
              other intellectual or proprietary right of any party; If reported
              pretending to others and using other’s Intellectual property.
            </li>
          </ul>

          <p className="font-bold text-lg">
            For the above situation, we will implement Enforcement B.
          </p>

          {/* <h1 className="text-xl  font-medium my-4">
            Watch Party Rules
          </h1> */}

          <p>
            <strong className="font-bold">
              Particularly when you use Share Slate Watch Party also known Fun
              Party, you agree to abide by the following rules at all times.
            </strong>{" "}
            You also agree not to encourage others to violate these rules. The
            latter is taken seriously and can also result in removal from the
            Service.
          </p>

          <ul
            className="ml-5 my-2"
            style={{
              listStyle: "inside",
              listStyleType: "initial",
            }}
          >
            <li>
              You shall be responsible for the actions of dual hosts in the
              Watch Party also known as Fun Party. If they violate the community
              guidelines, both of you will be reported and investigated;
            </li>
            <li>
              You, as a Moderator, shall be responsible for the viewers' actions
              in the Watch Party also known as Fun Party. If viewers violate the
              community guidelines, the Moderator has the responsibility to
              point out and take action to remove the viewer(s) from Watch
              Party;
            </li>
            <li>
              You, as a shop host, shall be responsible for the goods or
              services you sell. To ensure a good community environment, Share
              Slate prohibits the sale of alcohol, goods or services about child
              endangerment or abuse, drugs and drug paraphernalia, firearms, any
              illegal or stolen goods or unlawful services, all offensive
              content and activities, and other goods or services prohibited by
              App Store and Google.
            </li>
          </ul>

          <p className=" italic my-4">
            To learn more, please click the following links:
          </p>

          <ul
            className="ml-5 my-2"
            style={{
              listStyle: "inside",
              listStyleType: "initial",
            }}
          >
            <li>
              <a
                className="text-blue-400 underline"
                href="https://pay.google.com/intl/en_in/about/policy/?visit_id=636308113142509927-1253831182&rd=3"
              >
                Google Payments User Policies
              </a>
            </li>
            <li>
              <a
                className="text-blue-400 underline"
                href="https://support.google.com/googleplay/android-developer/answer/9217430?p=supplements&visit_id=637697137081932882-3779983373&rd=1&hl=en"
              >
                Prohibited pharmaceuticals and supplements - Play Console Help
              </a>
            </li>
            <li>
              <a
                className="text-blue-400 underline"
                href="https://developer.apple.com/app-store/review/guidelines/"
              >
                App Store Review Guidelines - Apple Developer
              </a>
            </li>
          </ul>
          {/* after copyig */}
          <h1 className="text-xl  font-medium my-4">DANGEROUS ACTS</h1>

          <p>
            We define risky activities or other dangerous behavior as acts
            conducted in a non-professional context or without the necessary
            skills and safety precautions that may lead to serious injury or
            death for the user or the public. We do not allow content that
            depicts, promotes, normalizes, or glorifies such behavior, including
            amateur stunts or dangerous challenges.
          </p>

          <p className="font-semibold text-lg">
            Do not post, upload, promote, share:
          </p>

          <ul
            className="ml-5 my-2"
            style={{
              listStyle: "inside",
              listStyleType: "initial",
            }}
          >
            <li>
              Content that shows the potentially inappropriate use of dangerous
              tools, vehicles, or objects
            </li>
            <li>Self-harm or eating disorder games, dares, pacts, or hoaxes</li>
            <li>
              Content that depicts or promotes ingesting substances that are not
              meant for consumption or could lead to severe harm or death
            </li>
            <li>
              Dangerous games, dares, or stunts that might lead to injury or
              death;
            </li>
            <li>
              Share links or affiliate codes to sites that contain pornographic,
              abusive, slots, roulette, or dice games.
            </li>
          </ul>

          <p>
            If you witness a Rules violation, please follow the instructions of
            reporting violations below to report it.
          </p>

          <p>
            Follow these links for our{" "}
            <a href="/privacypolicy" className="text-blue-400 underline">
              Privacy Policy
            </a>{" "}
            and{" "}
            <a href="/terms" className="text-blue-400 underline">
              Terms & Conditions
            </a>
            .
          </p>

          <h2 className="text-xl  font-medium my-4">Safety</h2>

          <h3 className="text-xl  font-medium my-4">CONTENT MODERATION</h3>

          <p>
            <strong className="font-bold">
              If someone has not violated any of the Rules, but you would still
              prefer not to interact with them, here are some tools you can
              utilize.
            </strong>
          </p>

          <p>
            <strong className=" italic my-4">
              Watch Party also known as Fun Party moderators decide who is in a
              room and can speak or view the material:
            </strong>
          </p>

          <ul
            className="ml-5 my-2"
            style={{
              listStyle: "inside",
              listStyleType: "initial",
            }}
          >
            <li>
              <strong className="font-bold"> Accept or reject viewers:</strong>{" "}
              If you are a Watch Party also known as Fun Party moderators you
              decide who can view or speak. If you’d like to invite someone from
              the audience to speak, just tap on the profile photo of that user
              and select "unmute." Moderator has the authority to accept or
              decline any user request during the watch party session.
            </li>
            <li>
              <strong className="font-bold">Remove viewer:</strong> Watch Party
              moderators can also temporarily mute a viewer. To mute the viewer,
              tap on their profile photo to open the half-sheet, and then tap
              the mute button to the right of their profile photo.
            </li>
          </ul>

          <p>
            <strong className=" italic my-4">
              Anyone can decide who they interact with:
            </strong>
          </p>

          <ul
            className="ml-5 my-2"
            style={{
              listStyle: "inside",
              listStyleType: "initial",
            }}
          >
            <li>
              <strong className="font-bold">Unfollow: </strong> You can unfollow
              a user at any time. To do so, navigate to the user’s profile and
              tap on the button that says "Following" to unselect it. They won’t
              be notified, and you won’t receive any further notifications about
              their activity.
            </li>
            <li>
              <strong className="font-bold">Block: </strong> You can block a
              user at any time. On their profile, click on the 3 horizontal dots
              in the top right, and select "Block this user." You can also block
              the user by tapping the share button on the home profile and
              select the option “Block User”.
            </li>
            <li>
              Blocked users will not be able to see your video and use comment,
              follow and DM.
            </li>
            <li>
              Blocked users will not be able to see or join any watch party that
              you create or in which you are a moderator.
            </li>
          </ul>

          <h3 className="text-lg  font-medium my-4">Reporting Violations</h3>

          <p>
            Share Slate is content-neutral, such that we won’t remove posts or
            users based solely on their expressed viewpoints.
          </p>

          <p>
            That said, to ensure the safety and privacy of our users and others,
            Share Slate relies upon its users to report violations of these
            Guidelines.
          </p>

          <p className="text-lg   font-bold my-4">
            To report a violation for a user, a video and a comment:
          </p>

          <ol
            className="ml-5 my-2"
            style={{
              listStyle: "inside",
              listStyleType: "initial",
            }}
          >
            <li>
              Visit the relevant user’s profile page and click the horizontal
              dots in the top right, and select "Report"
            </li>
            <li className="text-lg  font-medium my-4">Select a reason</li>
            <li>
              Provide Description (option but important) as much detail as
              possible and attach screenshots as evident.
            </li>
          </ol>

          <p>
            <strong className="font-bold">
              {" "}
              Here’s what you should know about reporting another user or their
              content for a Rule violation:
            </strong>
          </p>

          <ul
            className="ml-5 my-2"
            style={{
              listStyle: "inside",
              listStyleType: "initial",
            }}
          >
            <li>
              If you submit a report, we will not share your identity with the
              person you are reported.
            </li>
            <li>
              Please submit a report with attached evidence (screenshot,
              picture) or we can’t enforce it without them.
            </li>
            <li>
              All reports that are submitted will be reviewed and investigated.
              The need for and type of corrective or disciplinary action needed
              will be determined on a case-by-case basis.
            </li>
            <li>
              We seek to be fair, consistent, and responsive in addressing all
              reported incidents.
            </li>
            <li>
              If the violation directly targeted you, we will share certain
              information about the process and resolution with you (e.g., the
              stage of the investigation, any concerns that you may be at risk
              for immediate harm, information on how the resolution could impact
              you).
            </li>
            <li>
              Intentional, false reports of violations are treated as a
              violation of the Rules.
            </li>
          </ul>

          <p>
            <strong className="font-bold">
              If another user has reported you or your content for violating a
              Rule, here’s what you should know:
            </strong>
          </p>

          <ul
            className="ml-5 my-2"
            style={{
              listStyle: "inside",
              listStyleType: "initial",
            }}
          >
            <li>
              If a report has been filed against you, we will review and
              investigate your case. We may keep some details private in order
              to protect the reporting user(s).
            </li>
            <li>
              After reviewing your case, if you are violated the community
              guidelines, you will receive notifications in App or over email,
              if you are not violated, you won’t receive any notifications.
            </li>
            <li>
              We will notify you of the result of the investigation and its
              potential impact on you.
            </li>
            <li>
              If you believe a report has been falsely filed against you or you
              would like to appeal a decision, please follow the instructions
              mentioned in the violation email and submit as much context
              (words, video, picture) as possible. If your case was appealing
              successfully, your account or content will be restored. If not,
              you can resubmit the second appeal, which would be the final
              review of your account or content.
            </li>
            <li>
              Any attempt to retaliate against users who participate in
              reporting or investigation is a violation.
            </li>
          </ul>

          <p className="mt-5">
            We understand that not all Share Slate users will share our views as
            to what constitutes a violation of these Community Guidelines.
            However, to operate a functional platform, we must reserve the right
            to determine in our sole discretion what constitutes a violation.
          </p>

          <p>
            Before reporting violations, please keep in mind that people have
            differing opinions, reporting doesn’t guarantee a certain result,
            and that we reserve the right to decide whether to remove content or
            users at our sole discretion. For additional details see Section 9
            of the Terms & Conditions.
          </p>

          <h3 className="text-xl  font-medium my-4">Contact</h3>

          <p>
            We take these Community Guidelines seriously and ask that you follow
            them in the spirit in which they are intended. We further recognize
            that these guidelines must continually evolve, so please do contact
            us with questions, suggestions, or comments{" "}
            <a
              href="mailto:contact@shareslate.fun"
              className="text-blue-500 underline italic"
            >
              here
            </a>
          </p>

          <p>
            Thank you for reading, and for helping us build an amazing
            community!
          </p>

          <p>Team Share Slate</p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CommunityGuidelines;
