import React from "react";

// images
import premiumImg from "../assets/images/pricingImg.png";
import arrowImg from "../assets/images/arrowimg.png";

const Premiumsection = () => {
  return (
    <section className="w-[100%] py-[5rem]">
      <div className="w-[80%] mx-auto container">
        <h2 className="text-center text-[1.5rem] sm:text-[1.8rem] md:text-[2.5rem] font-bold ">
          Go ad-free with Share Slate Premium
        </h2>
        <div className="md:flex md:gap-10 mt-20">
          <div className="md:w-[35%] md:h-[550px] flex justify-center items-center w-[100%] h-[440px]">
            <img className="h-[100%]" src={premiumImg} alt="premiumImg" />
          </div>
          <div className="md:w-[65%] w-[100%]">
            <h2 className="mt-8 md:mt-0 text-center text-[1.8rem] text-[#515151] font-semibold mb-5">
              Share Slate Premium Features
            </h2>
            <div className="flex gap-2 items-center mb-2">
              <div>
                <img width="20px" height="20px" src={arrowImg} alt="arrowimg" />
              </div>
              <div className="text-[#A1A1A1] text-[13px]">
                Ads free entertainment
              </div>
            </div>
            <div className="flex gap-2 items-center mb-2">
              <div>
                <img width="20px" height="20px" src={arrowImg} alt="arrowimg" />
              </div>
              <div className="text-[#A1A1A1] text-[13px]">Verified profile</div>
            </div>
            <div className="flex gap-2 items-center mb-2">
              <div>
                <img width="20px" height="20px" src={arrowImg} alt="arrowimg" />
              </div>
              <div className="text-[#A1A1A1] text-[13px]">
                Access elite features
              </div>
            </div>
            <div className="flex gap-2 items-center mb-2">
              <div>
                <img width="20px" height="20px" src={arrowImg} alt="arrowimg" />
              </div>
              <div className="text-[#A1A1A1] text-[13px]">Premium support</div>
            </div>
            <h2 className="text-[1.2rem]  md:text-[2rem] gradientHeader p-2 mt-7 text-center text-white">
              Try ONE month free on us!
            </h2>
            {/* Price Plan*/}
            <div className="mt-[5rem]">
              <h3 className="text-[1.3rem] text-[#515151] font-semibold">
                Select one Plan
              </h3>

              <div className="flex mt-5 gap-10">
                <div className="flex flex-col items-center ">
                  <p className="text-[24px] font-bold text-[#5E72E4]">$10</p>
                  <h6 className="text-[1.2rem] font-bold my-2">Monthly</h6>
                  {/* <button class="bg-blue-500 text-white font-bold py-3 px-6 shadow-2xl inline-block  rounded-full">
                    Purchase
                  </button> */}
                </div>
                <div></div>
                <div className="flex flex-col items-center ">
                  <p className="text-[24px] font-bold text-[#5E72E4]">$100</p>
                  <h6 className="text-[1.2rem] font-bold my-2">Yearly</h6>
                  {/* <button class="bg-blue-500 text-white font-bold py-3 px-6 shadow-2xl inline-block  rounded-full">
                    Purchase
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Premiumsection;
