import React from "react";

// import funPartyImg from "../assets/images/funpartyimg.png";

const ImgDiv = ({ Img }) => {
  return (
    <div className="basis-[50%] flex justify-center">
      <img src={Img} className="md:h-[490px]" alt="imgDiv" />
    </div>
  );
};

export default ImgDiv;
