import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <section className="footerGradient text-white p-6  px-10 w-[100%]">
      <div className="flex justify-between flex-wrap gap-4">
        <div className="flex flex-wrap">
          <div className="text-[12px] leading-7 font-bold ">
            <Link to="/aboutus" target="_blank">
              {' '}
              ABOUT US <span className="inline-block mx-2">|</span>
            </Link>
          </div>
          {/* <div className="text-[12px] leading-7 font-bold ">
            <Link to="/blog" target="_blank">
              {' '}
              Blog <span className="inline-block mx-2">|</span>
            </Link>
          </div> */}
          <div className="text-[12px] leading-7 font-bold ">
            <Link to="/faqs" target="_blank">
              {' '}
              FAQ'S <span className="inline-block mx-2">|</span>
            </Link>
          </div>
          <div className="text-[12px] leading-7 font-bold">
            <Link to="/terms" target="_blank">
              TERMS & CONDITIONS <span className="inline-block mx-2">|</span>
            </Link>
          </div>
          <div className="text-[12px] leading-7 font-bold">
            <Link to="/privacypolicy" target="_blank">
              PRIVACY POLICY <span className="inline-block mx-2">|</span>
            </Link>
          </div>
          <div className="text-[12px] leading-7 font-bold">
            <Link to="/communityguidelines" target="_blank">
              COMMUNITY GUIDELINES <span className="inline-block mx-2">|</span>
            </Link>
          </div>
          <div className="text-[12px] leading-7 font-bold">
            <Link to="/cookiepolicy" target="_blank">
              {' '}
              COOKIE POLICY <span className="inline-block mx-2">|</span>
            </Link>
          </div>
          <div className="text-[12px] leading-7 font-bold">
            <Link to="/copyrightpolicy" target="_blank">
              COPYRIGHT POLICY <span className="inline-block mx-2">|</span>
            </Link>
          </div>
          {/* <div className="text-[12px] leading-7 font-bold">
            <Link to="/product" target="_blank">
              OUR PRODUCT <span className="inline-block mx-2">|</span>
            </Link>
          </div> */}
          <div className="text-[12px] leading-7 font-bold">
            <Link to="/contactus" target="_blank">
              CONTACT US
            </Link>
          </div>
        </div>
        <div className=" text-[14px]">
          © 2024 Shareslate.Inc All Rights Reserved{' '}
        </div>
      </div>
    </section>
  );
};

export default Footer;
