import React from 'react';
import CommonHeader from '../commoncomponents/CommonHeader';
import Footer from '../sections/Footer';

import funlandinglogo from '../assets/images/funlandinglogo.png';

const CookiePolicy = () => {
  return (
    <>
      <CommonHeader
        funlandinglogo={funlandinglogo}
        pageName="|  COOKIE POLICY"
      />

      <div className="w-[90%] ml-auto my-10 mr-auto  rounded-lg p-[2rem]">
        <section>
          <p>
            This cookie policy describes how Share Slate uses “Cookies” and
            other similar technologies, in connection with our Site and
            Services. Cookies allow us to recognize your device and store
            information about your preferences, to help provide a functioning
            website/application, to give us insight on marketing and to optimize
            content for use.
          </p>
          <p>
            By using the{' '}
            <a
              href="https://www.shareslate.com"
              className="text-[blue] underline"
              // target="_blank"
            >
              www.shareslate.com
            </a>{' '}
            or{' '}
            <a
              href="https://www.shareslate.fun"
              className="text-[blue] underline"
              // target="_blank"
            >
              www.shareslate.fun
            </a>{' '}
            site you agree to us using Cookies for the purpose of data
            analytics.
          </p>
        </section>

        <section>
          <h3 className="text-lg  font-medium my-4">1. WHAT ARE COOKIES?</h3>
          <p>
            Cookies are small text files that are stored in a computer's browser
            directory. They help site providers with things like understanding
            how people use a site, remembering a User's login details, and
            storing site preferences.
          </p>
        </section>

        <section>
          <h3 className="text-lg  font-medium my-4">
            2. DOES SHARE SLATE USE COOKIES?
          </h3>
          <p>
            Yes. We use cookies in accordance with our{' '}
            <a href="privacypolicy" className="text-[blue] underline">
              Privacy Policy
            </a>{' '}
            to:
          </p>
          <ul
            className="ml-5 my-2"
            style={{
              listStyle: 'inside',
              listStyleType: 'initial',
            }}
          >
            <li>Ensure that our Services function properly,</li>
            <li>Detect and prevent fraud,</li>
            <li>Understand how visitors use and engage with our Site, and</li>
            <li>Analyze and improve Services.</li>
          </ul>
        </section>

        <section>
          <h3 className="text-lg  font-medium my-4">
            3. WHO SETS COOKIES WHEN I USE SHARE SLATE SITE?
          </h3>
          <p>There are two main types of Cookies that can be set:</p>
          <ul
            style={{
              listStyle: 'inside',
              listStyleType: 'initial',
            }}
            className="ml-5 my-2"
          >
            <li>
              <span className="italic my-2 inline-block ">
                First party cookies:
              </span>{' '}
              These Cookies are placed and read by Share Slate directly when you
              use our Services,
            </li>
            <li>
              <span className="italic my-2 inline-block ">
                Third party Cookies:
              </span>{' '}
              These Cookies are not set by Share Slate, but by other companies,
              like Google or Stripe, for site analytics purposes.
            </li>
          </ul>
        </section>

        <section>
          <h3 className="text-lg  font-medium my-4">
            4. WHICH COOKIES DO WE USE?
          </h3>
          <p>
            <span className="italic my-2 inline-block font-semibold ">
              Necessary Cookies
            </span>
          </p>
          <p>
            We may store cookies on your device if they’re necessary for our
            operations and are required for you to navigate and use key
            functions throughout the website and web application.
          </p>
          <p>
            These cookies are necessary for the website to work properly and
            cannot be switched off in our systems. They are usually only set in
            response to actions made by you which amount to a request for
            services, such as setting your privacy preferences, logging in, or
            filling in forms.
          </p>
          <p>
            <span className="italic my-2 inline-block font-semibold">
              Analytical Cookies
            </span>
          </p>
          <p>
            These Cookies help us to understand how visitors engage with the
            website. We may use a set of Cookies to collect information and
            report site usage statistics.
          </p>
          <p>
            <span className="italic my-2 inline-block font-semibold">
              Functionality Cookies
            </span>
          </p>
          <p>
            We use a set of cookies that are optional for the website to
            function. They are usually only set in response to information
            provided to the website to personalize and optimize your experience.
          </p>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default CookiePolicy;
