import React from 'react';
import Footer from '../sections/Footer';
import Header from '../sections/Header';
// import TrendingFaqs from '../sections/faq/TrendingFaqs';
import CategoryFaqs from '../sections/faq/CategoryFaqs';
// import MostPopular from '../sections/blog/MostPopular';
// import MostPopularFaqs from '../sections/faq/MostPopularFaqs';

const Faqs = () => {
  return (
    <>
      <Header />
      {/* <TrendingFaqs /> */}
      <CategoryFaqs />
      {/* <MostPopularFaqs /> */}
      <Footer />
    </>
  );
};

export default Faqs;
