import React from 'react';
import Header from '../sections/Header';
import Footer from '../sections/Footer';
import FaqDetail from '../sections/faqdetail/FaqDetail';

const FaqsDetailPage = () => {
  return (
    <>
      <Header />
      <FaqDetail />
      <Footer />
    </>
  );
};

export default FaqsDetailPage;
