// import logo from "./logo.svg";
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import AboutPage from './pages/AboutPage';
import ContactUs from './pages/ContactUs';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import SignUp from './pages/SignUp';
import FunpartyPage from './pages/FunpartyPage';
// import ProductPage from "./pages/ProductPage";
import CommunityGuidelines from './pages/CommunityGuidelines';
import Terms from './pages/Terms';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CookiePolicy from './pages/CookiePolicy';
import CopyRightPolicy from './pages/CopyRightPolicy';
import Loader from './commoncomponents/Loader';
import InviteUsersPage from './pages/InviteUsersPage';
import OtpVerification from './commoncomponents/OtpVerification';
// import { Blog } from './pages/Blog';
// import BlogDetail from './pages/BlogDetail';
import Faqs from './pages/Faqs';
import FaqsDetailPage from './pages/FaqsDetailPage';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay (e.g., API call) to showcase the loader
    const delay = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    // Clear the timeout to avoid memory leaks
    return () => clearTimeout(delay);
  }, []);

  return (
    <>
      <div>
        {isLoading ? (
          <Loader />
        ) : (
          <Router>
            <Routes>
              <Route path="/" Component={HomePage}></Route>
              <Route path="/signin" Component={LoginPage}></Route>
              <Route path="/signup" Component={SignUp}></Route>
              <Route path="/otp" Component={OtpVerification}></Route>
              <Route path="/funparty" Component={FunpartyPage}></Route>
              <Route path="/inviteusers" Component={InviteUsersPage} />
              <Route path="/aboutus" Component={AboutPage}></Route>
              {/* <Route path="/blog" Component={Blog}></Route> */}
              {/* <Route path="/blog/:movieId" Component={BlogDetail}></Route> */}
              <Route path="/faqs" Component={Faqs}></Route>
              <Route path="/faqs/:movieId" Component={FaqsDetailPage}></Route>

              <Route path="/contactus" Component={ContactUs}></Route>
              {/* <Route path="/product" Component={ProductPage}></Route> */}
              <Route path="/terms" Component={Terms}></Route>
              <Route path="/privacypolicy" Component={PrivacyPolicy}></Route>
              <Route
                path="/communityguidelines"
                Component={CommunityGuidelines}
              ></Route>
              <Route path="/cookiepolicy" Component={CookiePolicy}></Route>
              <Route
                path="/copyrightpolicy"
                Component={CopyRightPolicy}
              ></Route>
              {/* <Route path="/product" Component={Pro}></Route> */}
            </Routes>
          </Router>
        )}
      </div>
    </>
  );
}

export default App;
