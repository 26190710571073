import React from "react";
import ImgDiv from "../commoncomponents/ImgDiv";
import ContentDiv from "../commoncomponents/ContentDiv";

import funpartyImg from "../assets/images/funpartyimg.png";
import arrowImg from "../assets/images/arrowimg.png";

const Funparty = () => {
  return (
    <section className="w-[100%] py-10 ">
      <div className="w-[80%] mx-auto  container">
        <div className="flex flex-col sm:flex sm:flex-row">
          <ImgDiv Img={funpartyImg} />
          <ContentDiv
            h2="Create and Join the Fun Party. "
            list1="Enjoy watch party with Friends to add more fun to your gatherings."
            list2="Add up to 5 users for free. Paid plan available"
            arrowImg={arrowImg}
          />
        </div>
      </div>
    </section>
  );
};

export default Funparty;
