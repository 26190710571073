import React from 'react';

import AnalyzeUsers from '../sections/AnalyzeUsers';
import Downloadappsection from '../sections/Downloadappsection';
import Entertainment from '../sections/Entertainment';
import FeaturesCreators from '../sections/FeaturesCreators';
import Footer from '../sections/Footer';
import Funparty from '../sections/Funparty';
import Header from '../sections/Header';
import Hero from '../sections/Hero';
import MeaningfulConnectionl from '../sections/MeaningfulConnectionl';
import Minis from '../sections/Minis';
import Premiumsection from '../sections/Premiumsection';
import SocialMediaSection from '../sections/SocialMediaSection';
import MiniHeader from '../sections/MiniHeader';

const HomePage = () => {
  return (
    <>
      <MiniHeader />
      <Header />

      <Hero />
      <Minis />
      <Entertainment />
      <Funparty />
      <FeaturesCreators />
      <AnalyzeUsers />
      <MeaningfulConnectionl />
      <Premiumsection />
      <Downloadappsection />
      <SocialMediaSection />
      <Footer />
    </>
  );
};

export default HomePage;
