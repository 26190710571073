import React from "react";

// import arrowImg from "../assets/images/arrowimg.png";

const ContentDiv = ({ h2, list1, list2, arrowImg }) => {
  return (
    <div className="basis-[50%] p-10 flex flex-col  justify-center gap-3 ">
      <h2 className="text-[1.4rem] md:text-[2rem] text-[#515151] leading-[2.2rem] font-medium mb-8 drop-shadow-2xl">
        {h2}
      </h2>
      <div className="flex gap-3 items-center">
        <div>
          <img width={20} height={20} src={arrowImg} alt="arrowimg" />
        </div>
        <div className="text-[#A1A1A1] text-[13px]">{list1}</div>
      </div>
      <div className="flex gap-3 items-center">
        <div>
          <img width="20px" height="20px" src={arrowImg} alt="arrowimg" />
        </div>
        <div className="text-[#A1A1A1] text-[13px]">{list2}</div>
      </div>
    </div>
  );
};

export default ContentDiv;
