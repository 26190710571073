import React from 'react';
import CommonHeader from '../commoncomponents/CommonHeader';
import Footer from '../sections/Footer';

// import Images
import funlandinglogo from '../assets/images/funlandinglogo.png';

const PrivacyPolicy = () => {
  return (
    <>
      <CommonHeader
        funlandinglogo={funlandinglogo}
        pageName="|  Privacy Policy"
      />
      <div className="w-[90%] ml-auto mr-auto  my-10 rounded-lg p-[2rem]">
        <h1 className="italic text-lg font-medium  my-4">
          Last Updated: November 22, 2023
        </h1>

        <p>
          This Privacy Policy explains how Share Slate Inc. and its subsidiaries
          and affiliated companies (
          <strong className="font-bold text-lg">“Share Slate</strong>,”{' '}
          <strong className="font-semibold text-lg">“we,”</strong> or{' '}
          <strong className="font-semibold text-lg">“us”</strong>) collects,
          uses, and discloses information about you. This Privacy Policy applies
          when you use our websites, mobile applications, and other online
          products and services that link to this Privacy Policy (collectively,
          our <strong className="font-bold text-lg"> “Services”)</strong>,
          contact our customer service team, engage with us on social media, or
          otherwise interact with us.
        </p>

        <p>
          We may change this Privacy Policy from time to time. If we make
          changes, we will notify you by revising the date at the top of this
          policy and, in some cases, we may provide you with additional notice
          (such as adding a statement to our website or sending you a
          notification). We encourage you to review this Privacy Policy
          regularly to stay informed about our information practices and the
          choices available to you.
        </p>

        <h2 className="text-lg  font-medium my-4">CONTENTS</h2>
        <ul>
          <li className="underline mt-3 text-blue-600">
            <a href="#collection">COLLECTION OF INFORMATION</a>
          </li>
          <li className="underline mt-3 text-blue-600">
            <a href="#use">USE OF INFORMATION</a>
          </li>
          <li className="underline mt-3 text-blue-600">
            <a href="#sharing">SHARING OF INFORMATION</a>
          </li>
          <li className="underline mt-3 text-blue-600">
            <a href="#advertising">ADVERTISING AND ANALYTICS</a>
          </li>
          <li className="underline mt-3 text-blue-600">
            <a href="#transfer">
              TRANSFER OF INFORMATION TO THE UNITED STATES AND OTHER COUNTRIES
            </a>
          </li>
          <li className="underline mt-3 text-blue-600">
            <a href="#choices">YOUR CHOICES</a>
          </li>
          <li className="underline mt-3 text-blue-600">
            <a href="#california">YOUR CALIFORNIA PRIVACY RIGHTS</a>
          </li>
          <li className="underline mt-3 text-blue-600">
            <a href="#contact">CONTACT US</a>
          </li>
        </ul>

        <h2 id="collection" className="text-lg  font-medium my-4">
          COLLECTION OF INFORMATION
        </h2>

        <h3 className="text-lg  font-medium my-4">
          Information You Provide to Us
        </h3>
        <p>
          We collect information you provide directly to us. For example, you
          share information directly with us when you create an account, fill
          out a form, submit or post content through our Services, make a
          purchase, communicate with us via third-party platforms, participate
          in a contest or promotion, request customer support, or otherwise
          communicate with us. The types of personal information we may collect
          include your name, email address, postal address, phone number, credit
          card and other payment information, and any other information you
          choose to provide.
        </p>

        <h3 className="text-lg  font-medium my-4">
          Information We Collect Automatically When You Interact with Us
        </h3>
        <p>
          When you access or use our Services or otherwise transact business
          with us, we automatically collect certain information, including:
        </p>
        <ul
          className="ml-5 my-2"
          style={{
            listStyle: 'inside',
            listStyleType: 'initial',
          }}
        >
          <li>
            <strong>Activity Information:</strong> We collect information about
            your activity on our Services, such as [videos clicked or uploaded,
            keywords searched, content liked or followed, content and comments
            posted, and direct messages sent to other users].
          </li>
          <li>
            <strong>Transactional Information:</strong> When you make a purchase
            or return, we collect information about the transaction, such as
            subscriptions, product details, purchase price, and the date and
            location of the transaction.
          </li>
          <li>
            <strong>Device and Usage Information:</strong> We collect
            information about how you access our Services, including data about
            the device and network you use, such as your hardware model,
            operating system version, mobile network, IP address, unique device
            identifiers, browser type, and app version. We also collect
            information about your activity on our Services, such as access
            times, pages viewed, links clicked, and the page you visited before
            navigating to our Services.
          </li>
          <li>
            <strong>Location Information:</strong> In accordance with your
            device permissions, we may collect information about the precise
            location of your device. You may stop the collection of precise
            location information at any time (see the Your Choices section below
            for details).
          </li>
          <li>
            <strong>
              Information Collected by Cookies and Similar Tracking
              Technologies:
            </strong>{' '}
            We (and our service providers) use tracking technologies, such as
            cookies and web beacons, to collect information about you. Cookies
            are small data files stored on your hard drive or in device memory
            that help us improve our Services and your experience, see which
            areas and features of our Services are popular, and count visits.
            Web beacons (also known as “pixel tags” or “clear GIFs”) are
            electronic images that we use on our Services and in our emails to
            help deliver cookies, count visits, and understand usage and
            campaign effectiveness. For more information about cookies and how
            to disable them, see the Your Choices section below.
          </li>
        </ul>
        <h2 className="text-lg  font-medium my-4">
          Information We Collect from Other Sources
        </h2>
        <p>
          We obtain information from third-party sources. For example, we may
          collect information about you from identity verification services,
          credit bureaus, advertising networks, data analytics providers, and
          mailing list providers. Additionally, if you create or log into your
          Share Slate account through a third-party platform (such as Apple or
          Google), we will have access to certain information from that
          platform, such as your name, birthday, and profile picture, in
          accordance with the authorization procedures determined by such
          platform. The information may be collected even if you are accessing
          the Share Slate or its services in the guest mode.
        </p>

        <h2 className="text-lg  font-medium my-4">Information We Derive</h2>
        <p>
          We may derive information or draw inferences about you based on the
          information we collect. For example, we may make inferences about your
          location based on your IP address, infer browsing preferences based on
          your use of the Services, or infer that you are looking to purchase
          certain products based on your browsing behavior and past purchases.
        </p>
        <h2 id="use" className="text-lg  font-medium my-4">
          USE OF INFORMATION
        </h2>
        <p>
          We use the information we collect to administer your account,
          customize your experience with us, and target advertisements based on
          your interests. We also use the information we collect to:
        </p>
        <ul
          className="ml-5 my-2"
          style={{
            listStyle: 'inside',
            listStyleType: 'initial',
          }}
        >
          <li>Provide, maintain, and improve our products and services;</li>
          <li>
            Process transactions and send you related information, including
            confirmations, receipts, invoices, and customer experience surveys;
          </li>
          <li>Personalize and improve your experience on our Services;</li>
          <li>
            Send you technical notices, security alerts, and support and
            administrative messages;
          </li>
          <li>
            Respond to your comments and questions and provide customer service;
          </li>
          <li>
            Communicate with you about products, services, and events offered by
            Share Slate and others and provide news and information that we
            think will interest you (see the{' '}
            <a className="text-blue-500 underline" href="#choices">
              {' '}
              Your Choices{' '}
            </a>{' '}
            section below for information about how to opt out of these
            communications at any time);
          </li>
          <li>
            Monitor and analyze trends, usage, and activities in connection with
            our Services;
          </li>
          <li>
            Personalize the advertisements you see on third-party platforms and
            <a href="#advertising"> ADVERTISING AND ANALYTICS</a> websites (for
            more information, see the{' '}
            <a className="text-blue-600 underline" href="#">
              {' '}
              Advertising and Analytics
            </a>
            section below);
          </li>
          <li>
            Personalize the advertisements you see when you use our Services
            based on information provided by our advertising partners;
          </li>
          <li>
            Facilitate contests, sweepstakes, and promotions and process and
            deliver entries and rewards;
          </li>
          <li>
            Detect, investigate, and prevent security incidents and other
            malicious, deceptive, fraudulent, or illegal activity and protect
            the rights and property of Share Slate and others;
          </li>
          <li>Debug, identify and repair errors in our Services;</li>
          <li>Comply with our legal and financial obligations; and</li>
          <li>
            Carry out any other purpose described to you at the time the
            information was collected.
          </li>
        </ul>

        <h2 id="sharing" className="text-lg  font-medium my-4">
          SHARING OF INFORMATION
        </h2>
        <p>
          We share personal information in the following circumstances or as
          otherwise described in this policy:
        </p>
        <ul
          className="ml-5 my-2"
          style={{
            listStyle: 'inside',
            listStyleType: 'initial',
          }}
        >
          <li>
            We share personal information with vendors, service providers, and
            consultants that need access to personal information to perform
            services for us, such as companies that assist us with web hosting,
            payment processing, fraud prevention, content moderation, customer
            service, and marketing and advertising.
          </li>
          <li>
            If you submit a review or post content in another public area of our
            Services, we share this information publicly on our Services.
          </li>
          <li>
            If you choose to use integrations we offer on our Services, such as
            sharing your location through our Google Maps integration, we may
            share certain information with the integration partners.
          </li>
          <li>
            We share personal information with third parties for their own
            services and marketing purposes, unless you opt out of this type of
            sharing by emailing us at{' '}
            <a
              href="mailto:contact@shareslate.fun"
              className="text-blue-500 underline"
            >
              contact@shareslate.fun
            </a>
            .
          </li>
          <li>
            We may disclose personal information if we believe that disclosure
            is in accordance with, or required by, any applicable law or legal
            process, including lawful requests by public authorities to meet
            national security or law enforcement requirements.
          </li>
          <li>
            We may share personal information if we believe that your actions
            are inconsistent with our user agreements or policies, if we believe
            that you have violated the law, or if we believe it is necessary to
            protect the rights, property, and safety of Share Slate, our users,
            the public, or others.
          </li>
          <li>
            We share personal information with our lawyers and other
            professional advisors where necessary to obtain advice or otherwise
            protect and manage our business interests.
          </li>
          <li>
            If you are an IOS user who wants to refund through Apple Store, we
            may share your consumption data (such as the consumption status,
            lifetime dollars purchased and refund, account tenure and other in
            need) on Share Slate platform with Apple Inc. to protect our
            legitimate interests. To see the Apple policy, please click{' '}
            <a
              className="text-blue-500 underline"
              href="https://developer.apple.com/documentation/storekit/skpaymenttransaction/1514963-init"
            >
              Customer Consented | Apple Developer Documentation
            </a>
            .
          </li>
          <li>
            We may share personal information in connection with, or during
            negotiations concerning, any merger, sale of company assets,
            financing, or acquisition of all or a portion of our business by
            another company.
          </li>
          <li>
            Personal information is shared between and among Share Slate and our
            current and future parents, affiliates, and subsidiaries and other
            companies under common control and ownership.
          </li>
          <li>
            We share personal information with your consent or at your
            direction.
          </li>
          <li>
            We also share aggregated or de-identified information that cannot
            reasonably be used to identify you.
          </li>
        </ul>
        <h2 id="advertising" className="text-lg  font-medium my-4">
          ADVERTISING AND ANALYTICS
        </h2>
        <p>
          We allow others to provide analytics services and serve advertisements
          on our behalf across the web and in mobile apps. These entities may
          use cookies, web beacons, device identifiers, and other technologies
          to collect information about your use of our Services and other
          websites and applications, including your IP address, web browser,
          mobile network information, pages viewed, time spent on pages or in
          mobile apps, links clicked, and conversion information. This
          information may be used by Share Slate and others to, among other
          things, analyze and track data, determine the popularity of certain
          content, deliver advertising and content targeted to your interests on
          our Services and other websites, and better understand your online
          activity.
        </p>

        <p>
          For more information about interest-based ads, or to opt out of having
          your web browsing information used for behavioral advertising
          purposes, please visit{' '}
          <a
            className="text-blue-600 underline"
            href="http://www.aboutads.info/choices"
          >
            www.aboutads.info/choices
          </a>{' '}
          or change your settings using the consent management tool on our
          Services. Your device may also include a feature (“Limit Ad Tracking”
          on iOS or “Opt Out of Interest-Based Ads” or “Opt Out of Ads
          Personalization” on Android) that allows you to opt out of having
          certain information collected through mobile apps used for behavioral
          advertising purposes.
        </p>

        <p>
          We also work with third parties to serve ads to you as part of
          customized campaigns on third-party platforms (such as Google). As
          part of these ad campaigns, we or the third-party platforms may
          convert information about you, such as your email address and phone
          number, into a unique value that can be matched with a user account on
          these platforms to allow us to learn about your interests and serve
          you advertising that is customized to your interests. Note that the
          third-party platforms may offer you choices about whether you see
          these types of customized ads.
        </p>

        <h2 id="transfer" className="text-lg  font-medium my-4">
          TRANSFER OF INFORMATION TO THE UNITED STATES AND OTHER COUNTRIES
        </h2>
        <p>
          Share Slate is headquartered in the United States, and we have
          operations and service providers in the United States and other
          countries. Therefore, we and our service providers may transfer your
          personal information to, or store or access it in, jurisdictions that
          may not provide levels of data protection that are equivalent to those
          of your home jurisdiction. We will take steps to ensure that your
          personal information receives an adequate level of protection in the
          jurisdictions in which we process it.
        </p>

        <h2 id="choices" className="text-lg  font-medium my-4">
          YOUR CHOICES
        </h2>
        <h3>Account Information</h3>
        <p>
          You may update and correct certain account information at any time by
          logging into your account, or emailing us at{' '}
          <a
            className="text-blue-600 underline"
            href="mailto:contact@shareslate.com"
          >
            contact@shareslate.com
          </a>
          . If you wish to delete your account, you may do so at any time by
          visiting “Settings & Privacy” under your profile in the app. If you
          still need help deleting your account, you may email us at{' '}
          <a
            className="text-blue-600 underline"
            href="mailto:contact@shareslate.fun"
          >
            contact@shareslate.fun
          </a>
          . Note that even after you delete your account, we may retain certain
          information as required by law or for our legitimate business
          purposes.
        </p>

        <h3 className="text-lg  font-medium my-4">Location Information</h3>
        <p>
          When you first launch any of our mobile apps that collect precise
          location information, you will be asked to consent to the app’s
          collection of this information. If you initially consent to our
          collection of such location information, you can subsequently stop the
          collection of this information at any time by changing the preferences
          on your mobile device. You may also stop our collection of this
          location information by following the standard uninstall process to
          remove all of our mobile apps from your device. If you stop our
          collection of this location information, some features of our Services
          may no longer function properly.
        </p>

        <h3 className="text-lg  font-medium my-4">Cookies</h3>
        <p>
          Most web browsers are set to accept cookies by default. If you prefer,
          you can usually adjust your browser settings to remove or reject
          browser cookies. Please note that removing or rejecting cookies could
          affect the availability and functionality of our Services.
        </p>

        <h3 className="text-lg  font-medium my-4">
          Communications Preferences
        </h3>
        <p>
          You may opt out of receiving text messages or promotional emails from
          Share Slate by following the instructions in those communications. You
          may also reach out to us at{' '}
          <a
            className="text-blue-600 underline"
            href="mailto:contact@shareslate.fun"
          >
            contact@shareslate.fun
          </a>{' '}
          in case of any difficulty while changing the communication
          preferences. If you opt out, we may still send you non-promotional
          emails, such as those about your account or our ongoing business
          relations.
        </p>

        <h3 className="text-lg  font-medium my-4">Mobile Push Notifications</h3>
        <p>
          With your consent, we may send push notifications to your mobile
          device. You can deactivate these messages at any time by changing the
          notification settings on your mobile device.
        </p>
        <h2 className="text-lg  font-medium my-4" id="california">
          YOUR CALIFORNIA PRIVACY RIGHTS
        </h2>
        <p>
          The California Consumer Privacy Act or “CCPA” (Cal. Civ. Code §
          1798.100 et seq.) affords consumers residing in California certain
          rights with respect to their personal information. If you are a
          California resident, this section applies to you.
        </p>

        <h3 className="text-lg  font-medium my-4">
          California Consumer Privacy Act
        </h3>
        <p>
          In the preceding 12 months, we have collected the following categories
          of personal information: identifiers, personal information that
          identifies, relates to, describes, or is capable of being associated
          with, a particular individual, commercial information, internet and
          other electronic network activity, geolocation data, electronic
          information, and inferences drawn from the aforementioned information.
          For details about the precise data points we collect and the
          categories of sources of such collection, please see the{' '}
          <a href="#collection" className="text-blue-600 underline">
            Collection of Information
          </a>{' '}
          section above. We collect personal information for the business and
          commercial purposes described in the{' '}
          <a href="#use" className="text-blue-600 underline">
            Use of Information
          </a>{' '}
          section above. Categories of personal information for business
          purposes to the following categories of recipients are mentioned
          below:
        </p>

        <div className="mb-4 mt-6 overflow-x-auto">
          <table className="min-w-full divide-y border divide-gray-200 ">
            <thead className="">
              <tr className="">
                <th className="p-4">Serial No.</th>
                <th className="p-4">Category of Personal Information</th>
                <th className="p-4">Categories of Recipients</th>
              </tr>
            </thead>
            <tbody className=" border divide-y divide-gray-200">
              <tr>
                <td className="px-6 py-4 whitespace-nowrap">1</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  Identifiers, commercial information, internet and other
                  electronic network activity, geolocation data, electronic
                  information, and inferences drawn from such data.
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  Data analytics providers.
                </td>
              </tr>
              <tr>
                <td className="px-6 py-4 whitespace-nowrap">2</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  Identifiers, personal information, commercial information,
                  internet and other electronic network activity, geolocation
                  data, and electronic information.
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  Database storage providers.
                </td>
              </tr>
              <tr>
                <td className="px-6 py-4 whitespace-nowrap">3</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  Identifiers, personal information, and electronic information.
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  Customer service providers.
                </td>
              </tr>
              <tr>
                <td className="px-6 py-4 whitespace-nowrap">4</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  Identifiers and electronic information.
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  Advertising networks.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>
          Share Slate does not sell your personal information. We do allow our
          advertising partners to collect certain device identifiers and
          electronic network activity via our Services to show ads that are
          targeted to your interests. To opt out of having your personal
          information used for targeted advertising purposes, please see the{' '}
          <a className="text-blue-600 underline" href="#advertising">
            Advertising and Analytics
          </a>{' '}
          section above.
        </p>

        <p>
          Subject to certain limitations, you have the right to (1) request to
          know more about the categories and specific pieces of personal
          information we collect, use, disclose, and sell, (2) request deletion
          of your personal information, (3) opt out of any “sales” of your
          personal information that may be occurring, and (4) not be
          discriminated against for exercising these rights. You may exercise
          your deletion rights by deleting your account by visiting “Settings &
          Privacy” under your profile options, in the app. If you still need
          help, or to exercise your other rights, you may email us at{' '}
          <a
            className="text-blue-600 underline"
            href="mailto:contact@shareslate.com"
          >
            contact@shareslate.com
          </a>
          . We may verify your request by asking you to provide information
          related to your recent interactions with us. We will not discriminate
          against you if you exercise your rights under the CCPA. If we receive
          your request from an authorized agent, we may ask for evidence that
          you have provided such agent with a power of attorney or that the
          agent otherwise has valid written authority to submit requests to
          exercise rights on your behalf. If you are an authorized agent seeking
          to make a request, please{' '}
          <a href="#contact" className="text-blue-600 underline">
            contact us
          </a>
          .
        </p>

        {/* new text */}
        <h2 className="text-lg  font-medium my-4">
          Privacy Policy for Contact List{' '}
        </h2>
        <p>
          This Privacy Policy outlines how we collect, use, disclose, and
          protect the information you provide when using our contact list and
          friends suggestion features. Your privacy is of utmost importance to
          us, and we are committed to ensuring the confidentiality and security
          of your personal information.
        </p>

        <h2 className="text-lg  font-medium my-4">
          1. Information We Collect:
        </h2>
        <h3 className="text-lg  font-medium my-4">
          a. Contact List Information:
        </h3>
        <ul
          className="ml-5 my-2"
          style={{
            listStyle: 'inside',
            listStyleType: 'initial',
          }}
        >
          <li>
            We collect the contact information you voluntarily provide,
            including names and phone numbers, to enhance your experience and
            enable you to connect with friends.
          </li>
        </ul>

        <h3 className="text-lg  font-medium my-4">b. Usage Data:</h3>
        <ul
          className="ml-5 my-2"
          style={{
            listStyle: 'inside',
            listStyleType: 'initial',
          }}
        >
          <li>
            We may collect data related to your interactions with the app, such
            as the frequency of use, features accessed, and preferences.
          </li>
        </ul>

        <h2 className="text-lg  font-medium my-4">
          2. How We Use Your Information:
        </h2>
        <h3 className="text-lg  font-medium my-4">a. Friends Suggestions:</h3>
        <ul
          className="ml-5 my-2"
          style={{
            listStyle: 'inside',
            listStyleType: 'initial',
          }}
        >
          <li>
            We use your contact list information to provide you with friend
            suggestions, helping you connect with people you may know.
          </li>
        </ul>

        <h3 className="text-lg  font-medium my-4">
          b. Enhancing User Experience:
        </h3>
        <ul
          className="ml-5 my-2"
          style={{
            listStyle: 'inside',
            listStyleType: 'initial',
          }}
        >
          <li>
            Your usage data helps us improve and personalize your experience
            with the app, making it more user-friendly and tailored to your
            preferences.
          </li>
        </ul>

        <h2 className="text-lg  font-medium my-4">3. Information Sharing:</h2>
        <h3 className="text-lg  font-medium my-4">a. Third-Party Services:</h3>
        <ul
          className="ml-5 my-2"
          style={{
            listStyle: 'inside',
            listStyleType: 'initial',
          }}
        >
          <li>
            We may share your contact information with third-party services to
            provide you with relevant products and services suggestions.
          </li>
        </ul>

        <h3 className="text-lg  font-medium my-4">b. Legal Compliance:</h3>
        <ul
          className="ml-5 my-2"
          style={{
            listStyle: 'inside',
            listStyleType: 'initial',
          }}
        >
          <li>
            We may disclose your information in response to legal requests, such
            as court orders or subpoenas, or to comply with applicable laws and
            regulations.
          </li>
        </ul>

        <h2 className="text-lg  font-medium my-4">4. Security Measures:</h2>
        <h3 className="text-lg  font-medium my-4">a. Data Security:</h3>
        <ul
          className="ml-5 my-2"
          style={{
            listStyle: 'inside',
            listStyleType: 'initial',
          }}
        >
          <li>
            We implement industry-standard security measures to protect your
            information from unauthorized access, disclosure, alteration, and
            destruction.
          </li>
        </ul>

        <h3 className="text-lg font-medium my-4">b. Encryption:</h3>
        <ul
          className="ml-5 my-2"
          style={{
            listStyle: 'inside',
            listStyleType: 'initial',
          }}
        >
          <li>
            Your data is transmitted securely using encryption protocols to
            safeguard it during transfer.
          </li>
        </ul>
        {/* 55555555 */}
        <h2 className="text-lg  font-medium my-4">5. User Controls:</h2>
        <h3 className="text-lg  font-medium my-4">a. Opt-Out:</h3>
        <ul
          className="ml-5 my-2"
          style={{
            listStyle: 'inside',
            listStyleType: 'initial',
          }}
        >
          <li>
            You have the option to opt-out of certain data collection and
            sharing features within the app settings.
          </li>
        </ul>

        <h3 className="text-lg  font-medium my-4">
          b. Data Access and Correction:
        </h3>
        <ul
          className="ml-5 my-2"
          style={{
            listStyle: 'inside',
            listStyleType: 'initial',
          }}
        >
          <li>
            You can request access to your personal information and make
            corrections by contacting us through the app or contacting us at{' '}
            <a
              href="mailto:contact@shareslate.fun"
              className="text-blue-500 underline"
            >
              contact@shareslate.fun
            </a>
            .
          </li>
        </ul>

        {/* 555 end */}

        {/* adding */}

        <h2 className="text-lg  font-medium my-4">
          6. Image Collection and Usage Policy
        </h2>
        <p>
          Share Slate Fun <strong>("we," "us," or "our")</strong> respects your
          privacy and is committed to protecting your personal information. This
          Privacy Policy explains how we collect, use, and disclose your
          information, including any images or photographs ("Images") that you
          provide or that are generated or collected while using our mobile
          application.
        </p>
        <h3 className="text-lg  font-medium my-4">a. Collection of Images:</h3>
        <p>
          We may collect Images that you voluntarily provide or upload while
          using our App. These Images may include, but are not limited to,
          user-generated photos, profile pictures, camera captures, or any other
          visual content that you choose to share within the App.
        </p>
        <h3 className="text-lg  font-medium my-4">
          b. Purpose of Image Collection:
        </h3>
        <p>
          The Images collected may be used for various purposes within the App,
          including but not limited to:
        </p>
        <ul
          className="ml-5 my-2"
          style={{
            listStyle: 'inside',
            listStyleType: 'initial',
          }}
        >
          <li>Displaying Images within the App's user interface.</li>
          <li>
            Enabling image-based features or functionalities offered by the App.
          </li>
          <li>
            Customizing user profiles or enhancing user experiences within the
            App.
          </li>
          <li>
            Any other purpose disclosed at the time of Image collection or as
            required by law.
          </li>
        </ul>

        <h3 className="text-lg  font-medium my-4">
          c. Usage of Collected Images:
        </h3>
        <p>We may use the collected Images in the following ways:</p>
        <ul
          className="ml-5 my-2"
          style={{
            listStyle: 'inside',
            listStyleType: 'initial',
          }}
        >
          <li>
            Displaying Images within the App's interface as intended by the
            user.
          </li>
          <li>
            Analyzing Images to provide certain features or functionalities
            within the App.
          </li>
          <li>
            Sharing Images with other users as part of the App's social or
            collaborative features.
          </li>
          <li>
            Any other usage explicitly consented to by the user or as required
            by law.
          </li>
        </ul>

        <h3 className="text-lg  font-medium my-4">d. Storage and Security:</h3>
        <p>
          We take reasonable measures to securely store and protect the Images
          collected through the App. This includes implementing encryption
          protocols, access controls, and other security measures to prevent
          unauthorized access, disclosure, alteration, or destruction of your
          Images.
        </p>

        <h3 className="text-lg  font-medium my-4">e. User Consent:</h3>
        <p>
          By using the App and providing Images, you consent to the collection,
          storage, and usage of your Images as described in this Privacy Policy.
          You acknowledge that you have the legal right to provide any Images
          you upload or share within the App.
        </p>

        <h3 className="text-lg  font-medium my-4">f. Third-Party Access:</h3>
        <p>
          We do not share your Images with third parties unless necessary for
          providing the services requested by you or as required by law. Any
          third-party access to your Images will be subject to their own privacy
          policies and terms of service.
        </p>

        <h3 className="text-lg  font-medium my-4">g. Data Retention:</h3>
        <p>
          We retain your Images only for as long as necessary to fulfill the
          purposes outlined in this Privacy Policy or as required by applicable
          laws or regulations. You may request the deletion of your Images by
          contacting us using the information provided in the "Contact Us"
          section below.
        </p>

        <h3 className="text-lg  font-medium my-4">h. User Rights:</h3>
        <p>
          You have the right to access, correct, or delete your Images stored
          within the App. You can exercise these rights by contacting us using
          the information provided below.
        </p>

        {/* dfkjdslfaaaaaaaaaaaaa */}

        <h2 className="text-lg  font-medium my-4">
          7. Updates to Privacy Policy:
        </h2>
        <h3 className="text-lg  font-medium my-4">a. Notification:</h3>
        <ul
          className="ml-5 my-2"
          style={{
            listStyle: 'inside',
            listStyleType: 'initial',
          }}
        >
          <li>
            We will notify you of any changes to this Privacy Policy, and your
            continued use of the app after such changes will indicate your
            acceptance.
          </li>
        </ul>

        <h2 className="text-lg  font-medium my-4" id="contact">
          CONTACT US
        </h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at{' '}
          <a
            href="mailto:contact@shareslate.fun"
            className="text-blue-500 underline"
          >
            contact@shareslate.fun
          </a>
          .
        </p>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;

// 6. Image Collection and Usage Policy

// Share Slate Fun ("we," "us," or "our") respects your privacy and is committed to protecting your personal information. This Privacy Policy explains how we collect, use, and disclose your information, including any images or photographs ("Images") that you provide or that are generated or collected while using our mobile application.

// a. Collection of Images:
// We may collect Images that you voluntarily provide or upload while using our App. These Images may include, but are not limited to, user-generated photos, profile pictures, camera captures, or any other visual content that you choose to share within the App.

// b. Purpose of Image Collection:
// The Images collected may be used for various purposes within the App, including but not limited to:

// Displaying Images within the App's user interface.
// Enabling image-based features or functionalities offered by the App.
// Customizing user profiles or enhancing user experiences within the App.
// Any other purpose disclosed at the time of Image collection or as required by law.

// c. Usage of Collected Images:
// We may use the collected Images in the following ways:

// Displaying Images within the App's interface as intended by the user.
// Analyzing Images to provide certain features or functionalities within the App.
// Sharing Images with other users as part of the App's social or collaborative features.
// Any other usage explicitly consented to by the user or as required by law.

// d. Storage and Security:
// We take reasonable measures to securely store and protect the Images collected through the App. This includes implementing encryption protocols, access controls, and other security measures to prevent unauthorized access, disclosure, alteration, or destruction of your Images.

// e. User Consent:
// By using the App and providing Images, you consent to the collection, storage, and usage of your Images as described in this Privacy Policy. You acknowledge that you have the legal right to provide any Images you upload or share within the App.

// f. Third-Party Access:
// We do not share your Images with third parties unless necessary for providing the services requested by you or as required by law. Any third-party access to your Images will be subject to their own privacy policies and terms of service.

// g. Data Retention:
// We retain your Images only for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by applicable laws or regulations. You may request the deletion of your Images by contacting us using the information provided in the "Contact Us" section below.

// h. User Rights:
// You have the right to access, correct, or delete your Images stored within the App. You can exercise these rights by contacting us using the information provided below.
